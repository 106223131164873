import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowUpRightFromSquare,
  faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "./bfec-logo-color.png";
import BKKLogo from "./bkk-logo.png";
import "./Navbar.scss";

interface LinkItem {
  text: string;
  link: string;
  sublinks?: LinkItem[];
}

const links: LinkItem[] = [
  {
    text: "Get plugged in",
    link: "/get-plugged-in",
    sublinks: [
      { text: "Latest", link: "latest" },
      { text: "Sermons", link: "sermons" },
      { text: "For Members", link: "members" },
      { text: "Visit Us", link: "visit-us" },
    ],
  },
  { text: "Who is Jesus?", link: "/who-is-jesus" },
  {
    text: "Our Community",
    link: "/our-community",
    sublinks: [
      { text: "About BFEC", link: "bfec" },
      { text: "People", link: "people" },
      { text: "Ministries", link: "ministries" },
      { text: "Testimonies", link: "testimonies" },
      { text: "Photo Gallery", link: "https://bfec.smugmug.com/" },
      { text: "READY Magazine", link: "http://bfec.sg/ready" },
    ],
  },
  {
    text: "Kindergarten",
    link: "/kindergarten",
    sublinks: [
      { text: "Testimonials", link: "#testimonials" },
      { text: "About Us", link: "#about-us" },
      { text: "Achievements", link: "#achievements" },
      { text: "Our Programme", link: "#programme" },
      {
        text: "Parents’ Portal",
        link: "https://parent.focusit.sg/bkk/parent_login.php?redirect=/bkk/index.php",
      },
    ],
  },
  { text: "Missions", link: "/missions" },
  { text: "Giving", link: "/giving" },
];

const HamburgerMenuItem = (props: {
  link: string;
  parentLink?: string;
  setIsOpen(o: boolean): void;
  children: any;
}) => {
  const { pathname, hash } = useLocation();

  if (props.link.startsWith("http")) {
    return (
      <ExternalLink className={""} link={props.link} text={props.children} />
    );
  }

  const link = (props.parentLink ? props.parentLink + "/" : "") + props.link;

  const className = pathname + hash === link ? "active" : "";

  return (
    <Link
      className={className}
      onClick={() => props.setIsOpen(false)}
      to={link}
    >
      {props.children}
    </Link>
  );
};

const Burger = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div id="hamburger-menu" aria-label="burger menu">
      <Menu
        right
        disableAutoFocus
        pageWrapId={"container"}
        outerContainerId={"page-wrap"}
        isOpen={isOpen}
        onStateChange={(state: any) => setIsOpen(state.isOpen)}
      >
        <HamburgerMenuItem link={"/"} setIsOpen={setIsOpen}>
          Home
        </HamburgerMenuItem>
        {links.map((link, i) => {
          if (!link.sublinks) {
            return (
              <HamburgerMenuItem key={i} link={link.link} setIsOpen={setIsOpen}>
                {link.text}
              </HamburgerMenuItem>
            );
          }

          return (
            <div className="burger-submenu" key={i}>
              <div key={i} className="subheader">
                {link.text}
              </div>
              {link.sublinks.map((sublink, j) => (
                <HamburgerMenuItem
                  key={"sub" + i + j}
                  parentLink={link.link}
                  link={sublink.link}
                  setIsOpen={setIsOpen}
                >
                  &nbsp;&nbsp;&mdash;&nbsp;&nbsp;{sublink.text}
                </HamburgerMenuItem>
              ))}
            </div>
          );
        })}
      </Menu>
    </div>
  );
};

const ExternalLink = (props: {
  className: string;
  link: string;
  text: string;
}) => {
  return (
    <a
      className={props.className}
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.text}
      <FontAwesomeIcon
        icon={faArrowUpRightFromSquare}
        style={{ marginLeft: "12px" }}
      />
    </a>
  );
};

const Dropdown = (props: { parentLink: string; links: LinkItem[] }) => {
  let linkClassName = "dropdown-link";
  return (
    <>
      <div className="dropdown-menu">
        {props.links.map((item, i) => {
          if (item.link.startsWith("http")) {
            return (
              <ExternalLink
                key={i}
                className={linkClassName}
                link={item.link}
                text={item.text}
              />
            );
          }

          if (item.link.startsWith("#")) {
            if (window.location.hash === item.link) {
              linkClassName = "dropdown-link active";
            } else {
              linkClassName = "dropdown-link";
            }

            return (
              <Link
                className={linkClassName}
                key={i}
                to={props.parentLink + "/" + item.link}
              >
                {item.text}
              </Link>
            );
          }

          return (
            <NavLink
              className={linkClassName}
              key={i}
              to={props.parentLink + "/" + item.link}
            >
              {item.text}
            </NavLink>
          );
        })}
      </div>
    </>
  );
};

const HorizontalNavbar = () => {
  const [dropdown, setDropdown] = useState("");

  return (
    <div id="nav-body" aria-label="horizontal navbar">
      <div id="nav-title">Bethesda Frankel Estate Church</div>
      <div id="nav-links">
        {links.map((link, i) => {
          let className = "link";
          if (i === links.length - 1) className += " last-link";

          if (!link.sublinks) {
            // Direct link
            if (link.link.startsWith("http")) {
              return (
                <a href={link.link} className={className} key={i}>
                  {link.text}
                </a>
              );
            }
            return (
              <NavLink key={i} to={link.link} className={className}>
                {link.text}
              </NavLink>
            );
          } else {
            // Dropdown
            return (
              <div
                key={i}
                className={className}
                onMouseEnter={() => setDropdown(link.link)}
                onMouseLeave={() => setDropdown("")}
              >
                <NavLink to={link.link}>{link.text} </NavLink>

                <FontAwesomeIcon icon={faChevronDown as IconProp} />
                {dropdown === link.link && (
                  <Dropdown parentLink={link.link} links={link.sublinks} />
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const Navbar = () => {
  const [atTop, setAtTop] = useState(true);
  const [bkk, setBkk] = useState(false);
  const [prevScroll, setPrevScroll] = useState(0);

  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setAtTop(window.scrollY < prevScroll ? true : window.scrollY < 60);
      setPrevScroll(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScroll]);

  useEffect(() => {
    setBkk(pathname.startsWith("/kindergarten"));
  }, [pathname]);

  return (
    <div id="public-navbar" className={atTop ? "nav-at-top" : "nav-not-at-top"}>
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="BFEC"></img>
        </Link>
        {bkk && (
          <Link to="/kindergarten">
            <img src={BKKLogo} alt="BKK" />
          </Link>
        )}
      </div>
      <HorizontalNavbar />
      <Burger />
    </div>
  );
};

export default Navbar;
