import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const MeetDetail = (props: { text: string; icon: IconProp }) => (
  <div className="meet-detail">
    <div className="icons">
      <FontAwesomeIcon fixedWidth icon={props.icon} />
    </div>
    <div>{parse(props.text)}</div>
  </div>
);
