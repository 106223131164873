import Scroll from "react-scroll";
import { BKKTestimonials as TestimonialImages } from "../ImageLibrary/imageLibrary";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const BKKTestimonials = () => {
  const [sel, setSel] = useState(0);

  const decSel = () => {
    setSel((i) => {
      if (i > 0) {
        return i - 1;
      } else {
        return TestimonialImages.length - 1;
      }
    });
  };

  const incSel = () => {
    setSel((i) => {
      if (i === TestimonialImages.length - 1) {
        return 0;
      } else {
        return i + 1;
      }
    });
  };

  return (
    <div id="bkk-testimonials" className="content-holder">
      <Scroll.Element name="testimonials" />
      <h2>Parent Testimonials</h2>
      <div id="bkk-testimonials-slider">
        <Btn icon={faAngleLeft} onClick={decSel} />
        <Images sel={sel} />
        <Btn icon={faAngleRight} onClick={incSel} />
        <div />
      </div>
      <div className="bkk-testimonials-mobile-btns">
        <Btn icon={faAngleLeft} onClick={decSel} mobile />
        <div className="spacer" />
        <Btn icon={faAngleRight} onClick={incSel} mobile />
      </div>
    </div>
  );
};

const Btn = (props: {
  icon: IconProp;
  onClick: () => void;
  mobile?: boolean;
}) => (
  <div
    className={"testimonial-btn " + (props.mobile ? "mobile" : "desktop")}
    onClick={props.onClick}
  >
    <FontAwesomeIcon icon={props.icon} fixedWidth />
  </div>
);

const Images = (props: { sel: number }) => {
  const before = props.sel === 0 ? TestimonialImages.length - 1 : props.sel - 1;
  const after = props.sel === TestimonialImages.length - 1 ? 0 : props.sel + 1;
  return (
    <div id="bkk-testimonials-slider-images">
      {TestimonialImages.map((img, i) => {
        let className = "hidden";
        if (i === props.sel) {
          className = "current";
        } else if (i === before) {
          className = "before";
        } else if (i === after) {
          className = "after";
        }

        return <img key={img} className={className} src={img} alt="" />;
      })}
      {/*<img className="before" src={TestimonialImages[before]} alt="" />*/}
      {/*<img*/}
      {/*  className="current"*/}
      {/*  src={TestimonialImages[props.sel]}*/}
      {/*  alt="Testimonial text"*/}
      {/*/>*/}
      {/*<img className="after" src={TestimonialImages[after]} alt="" />*/}
    </div>
  );
};
