import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePublicContext } from "../PublicContext";
import { NoData } from "../../components/NoData";
import { Post } from "../../components/ListingWithSearch/Post/Post";
import { SimilarPosts } from "../../components/ListingWithSearch/SimilarPosts/SimilarPosts";
import { AnnouncementInterface } from "../../components/Interfaces/Interfaces";
import { usePageUtils } from "../usePageUtils";

export const AnnouncementPost = () => {
  const { announcements } = usePublicContext();
  const { slug } = useParams();
  const { setTitleAndGroup } = usePageUtils();

  const [index, setIndex] = useState(0);
  const [data, setData] = useState<AnnouncementInterface>();

  useEffect(() => {
    if (!announcements) return;

    const indexFound = announcements.findIndex((x) => x.slug === slug);
    if (indexFound === -1) return;

    const dataFound = announcements[indexFound];
    setIndex(indexFound);
    setData(dataFound);

    setTitleAndGroup(dataFound.title || "Missing announcement", "Announcement");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, announcements]);

  if (!announcements || data === undefined) {
    return <NoData elementName="announcement" />;
  }

  return (
    <>
      <Post
        date={data.published_on.Time}
        title={data.title}
        files={data.files}
        postImage={data.image_link}
      >
        {parse(data.content, { trim: true })}
      </Post>
      <SimilarPosts
        title="Recent announcements"
        collection={announcements}
        currentIndex={index}
      />
    </>
  );
};
