import { HeroText } from "../Hero/Hero";
import { TestimoniesHero } from "../ImageLibrary/imageLibrary";
import { usePublicContext } from "../PublicContext";
import { ListingWithSearch } from "../../components/ListingWithSearch/ListingWithSearch";
import { TestimonyInterface } from "../../components/Interfaces/Interfaces";
import { TestimonyCard } from "./TestimonyCard";
import { usePageUtils } from "../usePageUtils";

export const TestimoniesContainer = () => {
  const { testimonies } = usePublicContext();
  usePageUtils().setTitleAndGroup("Testimonies", "Our Community");

  return (
    <div id="testimonies-container" className="listing-search-with-padding">
      <HeroText
        img={[TestimoniesHero]}
        text={"Testimonies"}
        align="top-right"
        width={4000}
        height={1778}
      />
      <ListingWithSearch
        elementName={"testimonies"}
        items={testimonies}
        card={buildTestimonyCard}
        searchFields={["title"]}
      />
    </div>
  );
};

const buildTestimonyCard = (testimony: TestimonyInterface) => (
  <TestimonyCard key={testimony.id} data={testimony} />
);
