import { MinistriesData } from "./MinistriesData";
import { MinistryLink } from "./MinistryLink";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import { OptionsContext } from "./MinistryOptionsContext";

export const PeopleMinistrySelector = () => {
  return (
    <section
      id="ministry-desktop-select"
      className="ministry-select white-card"
    >
      <div className="row">
        {MinistriesData.map((ministry, i) => (
          <MinistryLink key={i} ministry={ministry} />
        ))}
      </div>
    </section>
  );
};

export const MobileSelector = () => {
  const { selectedOption, setOption } = useContext(OptionsContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div id="ministry-mobile-select" className="white-card">
      <h2>Select Ministry:</h2>
      <FormControl fullWidth>
        <Select
          value={selectedOption}
          onChange={(e: any) => {
            if (e.target.value === "/kindergarten") {
              navigate(e.target.value);
            } else {
              setOption(e.target.value);
              navigate(pathname + "#" + e.target.value);
            }
          }}
        >
          {MinistriesData.map((ministry) => (
            <MenuItem value={ministry.link} key={ministry.link}>
              <span style={{ color: ministry.colour, fontWeight: "bold" }}>
                {ministry.name}
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
