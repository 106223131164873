import { SQLNullString } from "./Interfaces/Interfaces";
import { formatURL } from "../utils";

export const SeriesImage = (props: { data?: SQLNullString }) => {
  if (!props.data) return <></>;
  if (!props.data.String || props.data.String === "") return <></>;

  return (
    <div className="series-image">
      <img src={formatURL(props.data.String)} alt="Series illustration" />
    </div>
  );
};
