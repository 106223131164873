import {
  faGraduationCap,
  faHeartPulse,
  faPersonHiking,
  faUserChef,
} from "@fortawesome/pro-thin-svg-icons";
import { BtnsWithInfo } from "../AboutUs/AboutBFEC/BtnsWithInfo";

export const BKKSpecialActivities = () => (
  <BtnsWithInfo
    items={[
      {
        icon: faHeartPulse,
        name: "character",
        content: (
          <div>
            <h5>Character Education Programme</h5>
            <p>
              Children are nurtured in the areas of respect, independence,
              passion for learning, empathy and generosity, attentiveness, love
              and obedience, attentiveness, humility and truthfulness.
            </p>
            <p>
              These concepts are taught through the HEAD–HEART–HAND model using
              stories, object lessons, role play and practical experiences.
            </p>
          </div>
        ),
      },
      {
        icon: faUserChef,
        name: "practical",
        content: (
          <div>
            <h5>Practical Life Experiences</h5>
            <p>
              In relation to the sharing theme for each term, children make
              sandwiches, fruit and vegetable salad, fruit kebab and pizza.
            </p>
          </div>
        ),
      },
      {
        icon: faPersonHiking,
        name: "field-trip",
        content: (
          <div>
            <h5>Field Trips</h5>
            <p>
              Enriching field trips relating to the themes are organized.
              Besides creating meaningful learning experiences for the children,
              these trips also result in lasting and memorable impressions in
              their minds.
            </p>
          </div>
        ),
      },
      {
        icon: faGraduationCap,
        name: "concert",
        content: (
          <div>
            <h5>Annual Concert and Graduation</h5>
            <p>
              We culminate each year with a spectacular performance by our
              multi-talented adorable children. This highlight is a feast for
              the senses and an event parents look forward to with anticipation.
            </p>
          </div>
        ),
      },
    ]}
  />
);
