import { BKKBinoculars, BKKSafeSpaceHero } from "../ImageLibrary/imageLibrary";
import { Hero, HeroText } from "../Hero/Hero";
import Scroll from "react-scroll";

export const AboutBKK = () => (
  <>
    <HeroText
      img={[BKKSafeSpaceHero]}
      text={"A Safe Space for Learning"}
      align="bottom-left"
      width={1024}
      height={576}
    />
    <Scroll.Element name="about-us" />
    <div className="content-holder">
      <h2>About BKK</h2>
      <p>
        Bethesda Katong Kindergarten (BKK) is registered with ECDA (Early
        Childhood Development Agency) and is open to children of all
        nationalities and religions from 18 months to 6 years old.
      </p>
      <p>We are SPARK certified since 2017.</p>
      <p>
        Our facilities include 11 classrooms made conducive for learning. Each
        class is approximately 530 square feet with stimulating learning
        centres. There are two playgrounds, with sand and water play areas, and
        areas for tricycles and push bikes. In addition, an air-conditioned
        Computer Room, Activity Room, Music Room and Assembly Halls are
        available.
      </p>
    </div>

    <Hero img={[BKKBinoculars]} width={1024} height={640} />

    <div className="content-holder">
      <h2>Vision, Mission & Core Values</h2>
      <h3>Our Vision:</h3>
      <p>
        Every child a well-rounded individual rooted in Christian values.
        (adapted from Proverbs 22:6)
      </p>
      <h3>Our Mission:</h3>
      <p>
        With God’s guidance, we strive to provide a conducive and stimulating
        environment that nurtures each child’s holistic development.
      </p>
      <h3>Our Core Values:</h3>
      <table id="core-faith-values">
        <tbody>
          <tr>
            <th>R</th>
            <td>Respect</td>
          </tr>
          <tr>
            <th>I</th>
            <td>Independent</td>
          </tr>
          <tr>
            <th>P</th>
            <td>Passionate</td>
          </tr>
          <tr>
            <th>E</th>
            <td>Empathy</td>
          </tr>
        </tbody>
      </table>
      <p>
        The quality of time spent at kindergarten is crucial to future success
        in school. The years of early development establish the foundations for
        learning emotionally, socially, physically and intellectually.
      </p>
      <p>
        Young children begin learning in a variety of environments before they
        enter kindergarten – in their homes, in playgroup programmes and in the
        community. They bring varied levels of knowledge to any learning
        situation based on their environment.
      </p>
    </div>
  </>
);
