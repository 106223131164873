import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export const WhatsApp = (props: { number: string }) => {
  const whatsAppLink = `https://wa.me/+65${props.number.replace(" ", "")}`;

  return (
    <a href={whatsAppLink}>
      <FontAwesomeIcon
        icon={faWhatsapp}
        style={{ marginLeft: "4px", marginRight: "4px" }}
      />
      {props.number}
    </a>
  );
};

export const BFECWhatsApp = () => <WhatsApp number="9670 1800" />;
