import { PeopleData } from "./PeopleData";
import { Card, Grid, Paper } from "@mui/material";
import { HeroText } from "../../Hero/Hero";
import { PeopleCard } from "./PeopleCard";

interface SectionInterface {
  name: string;
  banner: string[];
  data: PeopleData[];
  width: number;
  height: number;
}

export const PeopleSection = ({ section }: { section: SectionInterface }) => (
  <Paper>
    <HeroText
      img={section.banner}
      text={section.name}
      align={"bottom-left"}
      width={section.width}
      height={section.height}
    />
    <Card
      sx={{
        maxWidth: "1600px",
        width: "100%",
        margin: "0 auto",
        minHeight: "600px",
      }}
      elevation={0}
    >
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: "100%",
          margin: 0,
          padding: "32px 12px",
        }}
        className="people-grid"
      >
        {section.data.map((person, i) => {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              key={"persongrid" + i}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <PeopleCard data={person} key={"peoplecard" + i} />
            </Grid>
          );
        })}
      </Grid>
    </Card>
  </Paper>
);
