import { HeroText } from "../../Hero/Hero";
import { AboutPeopleBanner } from "../../ImageLibrary/imageLibrary";
import { OperationsTeam, PastoralTeam } from "./ChurchStaff";
import { useState } from "react";
import { Team, TeamSelector } from "./TeamSelector";
import "./People.scss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Council } from "./Council";
import { PersonModal } from "./PersonModal";
import { PeopleData } from "./PeopleData";
import { PeopleContext } from "./PeopleContext";
import { usePageUtils } from "../../usePageUtils";

export const People = () => {
  const [team, setTeam] = useState(Team.Council);
  const [modalPerson, setModalPerson] = useState<PeopleData | null>(null);

  usePageUtils().setTitleAndGroup("People", "Our Community");

  return (
    <PeopleContext.Provider value={{ modalPerson, setModalPerson }}>
      <div id="people">
        <PersonModal close={() => setModalPerson(null)} person={modalPerson} />
        <HeroText
          img={AboutPeopleBanner}
          text="People"
          align="bottom-right"
          width={4032}
          height={2851}
        />
        <TeamSelector team={team} setTeam={setTeam} />
        <TransitionGroup className="transition-container">
          {team === Team.Pastoral && (
            <CSSTransition timeout={700} classNames="page-fade">
              <PastoralTeam />
            </CSSTransition>
          )}
          {team === Team.Operations && (
            <CSSTransition timeout={700} classNames="page-fade">
              <OperationsTeam />
            </CSSTransition>
          )}
          {team === Team.Council && (
            <CSSTransition timeout={700} classNames="page-fade">
              <Council />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </PeopleContext.Provider>
  );
};
