import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

const Btn = (props: {
  icon: IconProp;
  name: string;
  selected: boolean;
  onClick: () => void;
}) => {
  const className = "info-btn " + (props.selected ? "info-btn-selected" : "");

  return (
    <div aria-label={props.name} className={className} onClick={props.onClick}>
      <FontAwesomeIcon icon={props.icon} />
    </div>
  );
};

const Btns = (props: {
  selected: string;
  setSelected: (s: string) => void;
  items: InfoItem[];
}) => (
  <div className="row">
    {props.items.map((x) => (
      <Btn
        key={x.name}
        name={x.name}
        icon={x.icon}
        selected={props.selected === x.name}
        onClick={() => props.setSelected(x.name)}
      />
    ))}
  </div>
);

export interface InfoItem {
  icon: IconProp;
  name: string;
  content: React.ReactElement;
}

export const BtnsWithInfo = (props: { header?: string; items: InfoItem[] }) => {
  const [selected, setSelected] = useState(props.items[0].name);

  useEffect(() => {
    try {
      // @ts-ignore
      window.refTagger.tag();
    } catch {}
  }, [selected]);

  const selectedItem = props.items.find((x) => x.name === selected);

  return (
    <div className="about-bfec-btns">
      {props.header !== undefined && <h2>{props.header}</h2>}
      <div className="about-bfec-btns-content">
        <Btns
          selected={selected}
          setSelected={setSelected}
          items={props.items}
        />
        <div>
          <h3>{selectedItem?.name}</h3>
          {selectedItem?.content}
        </div>
      </div>
    </div>
  );
};
