import { TimelineItem } from "../../../components/TimelineItem/TimelineItem";

export const OurStory = () => (
  <div>
    <h2>What’s our story?</h2>
    <div id="our-story">
      <TimelineItem date="1953">
        The seeds were first sown when Bethesda (Katong) Church opened the
        Bethesda Katong Kindergarten as part of their vision of an outreach at
        Frankel Estate.
      </TimelineItem>
      <TimelineItem date="1958">
        Those seeds were tended to and watered by the newly formed Frankel
        Committee. Running separately from the Kindergarten, they organised
        Gospel meetings and Lord’s Supper services.
      </TimelineItem>
      <TimelineItem date="1964">
        We got a new home! The Bethesda (Frankel Estate) Church building on 4 La
        Salle Street as we know it today was dedicated and opened on 21 November
        1964, to accommodate our larger and growing family.
      </TimelineItem>
      <TimelineItem date="1973">
        All grown up! Bethesda (Frankel Estate) Church gains full autonomy from
        Bethesda (Katong) Church.
      </TimelineItem>
    </div>
  </div>
);
