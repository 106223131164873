import React from "react";
import { PeopleData } from "./PeopleData";

interface PeopleContextType {
  modalPerson: PeopleData | null;
  setModalPerson: (p: PeopleData | null) => void;
}

export const PeopleContext = React.createContext<PeopleContextType>({
  modalPerson: null,
  setModalPerson: () => {},
});
