import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SermonInterface } from "../../components/Interfaces/Interfaces";
import { usePublicContext } from "../PublicContext";
import { NoData } from "../../components/NoData";
import { Post } from "../../components/ListingWithSearch/Post/Post";
import "./SermonsPost.scss";
import { SermonInformation } from "./SermonInformation";
import { Services } from "./Services";
import { SimilarPosts } from "../../components/ListingWithSearch/SimilarPosts/SimilarPosts";
import { usePageUtils } from "../usePageUtils";

export const SermonPost = () => {
  const { slug } = useParams<Record<string, string | undefined>>();

  const index = useRef(0);
  const [sermon, setSermon] = useState<SermonInterface>();
  const { sermons } = usePublicContext();
  const { setTitleAndGroup } = usePageUtils();

  useEffect(() => {
    if (!sermons) return;

    index.current = sermons.findIndex((x) => x.slug === slug);
    if (index.current === -1) return;

    const sermonFound = sermons[index.current];
    setSermon(sermonFound);
    setTitleAndGroup(sermonFound.title || "Missing sermon", "Sermon");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, sermons]);

  useEffect(() => {
    try {
      // @ts-ignore
      window.refTagger.tag();
    } catch {}
  }, [sermon]);

  if (!sermons || !sermon) return <NoData elementName="sermons" />;

  return (
    <>
      <Post
        date={sermon.sermon_date.Time}
        title={sermon.title}
        sermonSeries={sermon.series}
        postImage={sermon.series.image_link}
        sidebar={<SermonInformation sermon={sermon} />}
        files={sermon.files}
        key={sermon.id + sermon.slug}
      >
        <Services sermon={sermon} />
      </Post>
      <SimilarPosts
        title="Recent sermons"
        collection={sermons}
        currentIndex={index.current}
      />
    </>
  );
};
