import { ReactElement, useContext } from "react";
import { ListingWithSearchContext } from "../ListingWithSearchContext";
import { Filters } from "../Filters/Filters";
import { Pagination } from "../Pagination/Pagination";
import { NoData } from "../../NoData";
import "./PostsListing.scss";
import { FilterBlurb } from "./FilterBlurb";
import Scroll from "react-scroll";

export const PostsListing = (props: {
  title?: string;
  newAction?: string | (() => void);
  newElement?: ReactElement;
}) => (
  <div className="listing">
    <Scroll.Element name="listing-top" />
    <div className="row">
      {props.title && <h1>{props.title}</h1>}
      <div className="spacer" />
      <Filters newAction={props.newAction} />
    </div>
    <FilterBlurb />
    {props.newElement}
    <Results />
    <Pagination />
  </div>
);

const Results = () => {
  const ctx = useContext(ListingWithSearchContext);

  if (ctx.pageItems.length === 0)
    return (
      <div className="listing-results">
        <NoData elementName={ctx.elementName} />
      </div>
    );

  return (
    <div className="listing-results grid-layout">
      {ctx.pageItems.map(ctx.card)}
    </div>
  );
};
