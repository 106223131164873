import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { usePublicContext } from "../PublicContext";
import { NoData } from "../../components/NoData";
import { Post } from "../../components/ListingWithSearch/Post/Post";
import { usePageUtils } from "../usePageUtils";

export const TestimonyPost = () => {
  const { testimonies } = usePublicContext();
  const { slug } = useParams();
  const { setTitleAndGroup } = usePageUtils();

  const data = testimonies?.find((x) => x.slug === slug);
  if (data === undefined) {
    return <NoData elementName="testimony" />;
  }

  setTitleAndGroup(data.title, "Testimonies");

  let postImage;
  let files = data.files;

  if (
    data.files.length > 0 &&
    data.files[0].file_link.match(/.(jpg|jpeg|png|gif)$/i)
  ) {
    postImage = {
      String: data.files[0].file_link,
      Valid: true,
    };
    files = files.slice(1);
  }

  return (
    <Post title={data.title} files={files} postImage={postImage}>
      {parse(data.content, { trim: true })}
    </Post>
  );
};
