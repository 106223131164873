/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull frontend'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/bfec/BFEC/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/bfec/BFEC/implementation/frontend)
 */

import * as amplitude from "@amplitude/analytics-browser";

export type Environment = "production" | "development" | "staging";

export const ApiKey: Record<Environment, string> = {
  production: "e378c16355582e88cacb61b5c122f200",
  development: "4cabb0590f1b657b9a0124dae85bfc9b",
  staging: "285333055377fa827f95f528c91ac2b0",
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: "2",
    branch: "main",
    source: "frontend",
    versionId: "d31ec3e0-359b-4c41-af00-2be88a6fc354",
  },
  ...{
    ingestionMetadata: {
      sourceName: "browser-typescript-ampli",
      sourceVersion: "2.0.0",
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  initial_dclid?: any;
  initial_fbclid?: any;
  initial_gbraid?: any;
  initial_gclid?: any;
  initial_ko_click_id?: any;
  initial_msclkid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_ttclid?: any;
  initial_twclid?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_id?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  initial_wbraid?: any;
}

export interface PostSearchProperties {
  "Element Name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Num Results": number;
  "Search String": string;
}

export interface ViewedSeriesProperties {
  Name: string;
}

export interface ViewedSpeakerProperties {
  Name: string;
}

export class Identify implements BaseEvent {
  event_type = "Identify";

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class PostSearch implements BaseEvent {
  event_type = "Post Search";

  constructor(public event_properties: PostSearchProperties) {
    this.event_properties = event_properties;
  }
}

export class ViewedSeries implements BaseEvent {
  event_type = "Viewed Series";

  constructor(public event_properties: ViewedSeriesProperties) {
    this.event_properties = event_properties;
  }
}

export class ViewedSpeaker implements BaseEvent {
  event_type = "Viewed Speaker";

  constructor(public event_properties: ViewedSpeakerProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Post Search
   *
   * [View in Tracking Plan](https://data.amplitude.com/bfec/BFEC/events/main/latest/Post%20Search)
   *
   * Owner: Lee Yen Ter
   *
   * @param properties The event's properties (e.g. Element Name)
   * @param options Amplitude event options.
   */
  postSearch(
    properties: PostSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new PostSearch(properties), options);
  }

  /**
   * Viewed Series
   *
   * [View in Tracking Plan](https://data.amplitude.com/bfec/BFEC/events/main/latest/Viewed%20Series)
   *
   * Owner: Lee Yen Ter
   *
   * @param properties The event's properties (e.g. Name)
   * @param options Amplitude event options.
   */
  viewedSeries(
    properties: ViewedSeriesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedSeries(properties), options);
  }

  /**
   * Viewed Speaker
   *
   * [View in Tracking Plan](https://data.amplitude.com/bfec/BFEC/events/main/latest/Viewed%20Speaker)
   *
   * Owner: Lee Yen Ter
   *
   * @param properties The event's properties (e.g. Name)
   * @param options Amplitude event options.
   */
  viewedSpeaker(
    properties: ViewedSpeakerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedSpeaker(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
