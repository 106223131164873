import { useRef } from "react";

export const usePageUtils = () => {
  const prevTitle = useRef("");

  return {
    setTitleAndGroup: (title: string, group: string) => {
      if (title === "") {
        document.title = "Bethesda Frankel Estate Church";
      } else {
        document.title = title + " | " + (group === "BKK" ? "BKK" : "BFEC");
      }

      if (title !== prevTitle.current) {
        prevTitle.current = title;
        // @ts-ignore
        const analytics = window.firebase?.analytics;
        if (typeof analytics === "function") {
          const page_path = window.location.pathname + window.location.search;
          // @ts-ignore
          gtag("set", "page_path", page_path);
          // @ts-ignore
          gtag("set", "content_group", group);
          // @ts-ignore
          gtag("event", "page_view");
        }
      }
    },
  };
};
