import "./Loading.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

export const Loading = () => (
  <div className="loading">
    <div>Loading, please wait...</div>
    <FontAwesomeIcon icon={faSpinnerThird} spin />
  </div>
);
