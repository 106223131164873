import { Avatar, AvatarGroup } from "@mui/material";
import { SpeakerSeriesData } from "../Interfaces/Interfaces";
import { formatURL } from "../../utils";

export const SpeakerAvatar = (props: { speaker: SpeakerSeriesData[] }) => {
  if (props.speaker == null) {
    return <></>;
  }
  return (
    <AvatarGroup max={3} spacing={"small"}>
      {props.speaker.map((speaker) => {
        if (speaker.image_link !== null && speaker.image_link.String !== null) {
          return (
            <Avatar
              key={speaker.id}
              alt={speaker.name}
              src={
                speaker.image_link.Valid
                  ? formatURL(speaker.image_link.String)
                  : "404"
              }
              sx={{
                width: 64,
                height: 64,
              }}
            />
          );
        }
        return <></>;
      })}
    </AvatarGroup>
  );
};
