import { MinistryDataItem } from "./MinistriesData";
import { CSSProperties, useContext } from "react";
import { Link } from "react-router-dom";
import { OptionsContext } from "./MinistryOptionsContext";

export const MinistryLink = (props: { ministry: MinistryDataItem }) => {
  const { selectedOption, setOption } = useContext(OptionsContext);

  let className = props.ministry.link;
  const style: CSSProperties = {};

  if (selectedOption === props.ministry.link) {
    className = props.ministry.link + " active";
    style.backgroundColor = props.ministry.colour;
  } else {
    style.color = props.ministry.colour;
  }

  let redirect: string;

  if (props.ministry.link.startsWith("/")) {
    redirect = props.ministry.link;
  } else {
    redirect = "/our-community/ministries#" + props.ministry.link;
  }

  return (
    <Link
      to={redirect}
      className={className}
      style={style}
      onClick={() => setOption(props.ministry.link)}
    >
      {props.ministry.name}
    </Link>
  );
};
