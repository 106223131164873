import { PeopleSection } from "./PeopleSection";
import {
  OperationsBanners,
  PastoralBanners,
} from "../../ImageLibrary/imageLibrary";
import { OperationsPeople, PastoralPeople } from "./PeopleData";

export const PastoralTeam = () => (
  <PeopleSection
    section={{
      name: "Pastoral Team",
      banner: PastoralBanners,
      data: PastoralPeople,
      width: 2560,
      height: 1138,
    }}
  />
);

export const OperationsTeam = () => (
  <PeopleSection
    section={{
      name: "Operations Team",
      banner: OperationsBanners,
      data: OperationsPeople,
      width: 2560,
      height: 1138,
    }}
  />
);
