export enum Team {
  Council,
  Pastoral,
  Operations,
}

export const TeamSelector = (props: {
  team: Team;
  setTeam: (t: Team) => void;
}) => (
  <div id="team-selector">
    <TeamButton
      onClick={() => props.setTeam(Team.Council)}
      selected={props.team === Team.Council}
    >
      Elders &amp; Deacons
    </TeamButton>
    <TeamButton
      onClick={() => props.setTeam(Team.Pastoral)}
      selected={props.team === Team.Pastoral}
    >
      Pastoral Team
    </TeamButton>
    <TeamButton
      onClick={() => props.setTeam(Team.Operations)}
      selected={props.team === Team.Operations}
    >
      Operations Team
    </TeamButton>
  </div>
);

export const TeamButton = (props: {
  onClick: () => void;
  children: string;
  selected: boolean;
}) => (
  <div
    className={"btn btn-" + (props.selected ? "primary" : "secondary")}
    onClick={props.onClick}
  >
    {props.children}
  </div>
);
