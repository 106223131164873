import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { EmailLink } from "../../components/contact-components/EmailLink/EmailLink";
import "./Footer.scss";
import { Phone } from "../../components/contact-components/Phone/Phone";

interface FooterData {
  facebook: string;
  insta: string;
  youtube?: string;
  visit?: string;
  officeHours?: string;
  address: string;
  phone: string;
  fax?: string;
  email: string;
}

const BKKFooter: FooterData = {
  facebook: "https://www.facebook.com/BethesdaKatongKindergarten/",
  insta: "https://www.instagram.com/bethesdakatong/",
  visit: "Please contact us to make an appointment to view the school.",
  officeHours: "Monday–Friday, 8:00am–3:00pm",
  address: "2 La Salle Street, Singapore (456929)",
  phone: "+65 6442 2622",
  fax: "6448 0831",
  email: "bkk@bfec.org.sg",
};

const BFECFooter: FooterData = {
  facebook: "https://www.facebook.com/bfecsg/",
  insta: "https://www.instagram.com/bfecsg/",
  youtube: "https://www.youtube.com/channel/UCLHNZTe5qfFFN3u7EcqgxaQ",
  address: "4 La Salle Street Singapore (456930)",
  phone: "+65 6448 1800",
  email: "bfec@bfec.org.sg",
};

const Footer = () => {
  const { pathname } = useLocation();
  const data = pathname.startsWith("/kindergarten") ? BKKFooter : BFECFooter;

  return (
    <footer>
      <section className="footer-top">
        <aside id="contact-info">
          <h2>Contact Info</h2>
          <div id="contact-info-content">
            {data.officeHours && (
              <div className="visit-us">
                <h3>Office Hours</h3>
                <p>{data.officeHours}</p>
              </div>
            )}
            <div className="footer-address">
              <h3>Address</h3>
              <p>{data.address}</p>
            </div>
            <div className="footer-phone">
              <h3>Phone Number</h3>
              <p>
                <Phone num={data.phone} />
              </p>
            </div>
            {data.fax && (
              <div className="footer-fax">
                <h3>Fax</h3>
                <p>
                  <Phone num={data.fax} />
                </p>
              </div>
            )}
            <div className="footer-email">
              <h3>Email Address</h3>
              <p>
                <EmailLink to={data.email} />
              </p>
            </div>
            <div className="footer-social-media-btns">
              <a href={data.facebook}>
                <FontAwesomeIcon icon={faFacebook as IconProp} />
              </a>
              <a href={data.insta}>
                <FontAwesomeIcon icon={faInstagram as IconProp} />
              </a>
              {data.youtube && (
                <a href={data.youtube}>
                  <FontAwesomeIcon icon={faYoutube as IconProp} />
                </a>
              )}
            </div>
          </div>
        </aside>
      </section>
      <section id="privacy-notice">
        <p>
          &copy; {new Date().getFullYear()} All Rights Reserved. Bethesda
          Frankel Estate Church.
        </p>
        <p>
          By providing your personal data to Bethesda Frankel Estate Church in
          our worship services, activities or events organized by BFEC and her
          ministries, you consent to BFEC’s collection, use and disclosure of
          your personal data (and/or the personal data of any person on whose
          behalf you are validly acting for or authorised to provide consent)
          for our church activities, programs planning and administration
          purposes in accordance with our Personal Data Protection Policy.
        </p>
        <p>
          Click{" "}
          <span>
            <a
              href="https://uploads.bfec.org.sg/BFEC%20PDPP.pdf"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </span>{" "}
          to access our Personal Data Protection Policy. If you wish to withdraw
          or limit your consent, please write to our Data Protection Officer at
          the address below:
        </p>
        <p>
          Data Protection Officer (email:{" "}
          <EmailLink to="bfec" subject="Change of PDPA consent" />; tel:{" "}
          <Phone />)
        </p>
      </section>
    </footer>
  );
};

export default Footer;
