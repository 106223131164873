import { Link } from "react-router-dom";
import { TestimonyInterface } from "../../components/Interfaces/Interfaces";
import { buildBlurb } from "../../utils";

export const TestimonyCard = (props: { data: TestimonyInterface }) => {
  const data = props.data;
  let content: JSX.Element;

  if (
    data.files.length > 0 &&
    data.files[0].file_link.match(/.(jpg|jpeg|png|gif)$/i)
  ) {
    content = <img className="card-img" src={data.files[0].file_link} alt="" />;
  } else {
    content = (
      <div className="card-text">
        <div className="card-title">{data.title}</div>
        <div className="card-blurb">{buildBlurb(data.content)}</div>
      </div>
    );
  }

  return (
    <Link
      to={"/our-community/testimonies/" + data.slug}
      className={"card testimonies four"}
    >
      <div className="card-content">{content}</div>
    </Link>
  );
};
