import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { OrderedFile } from "../Interfaces/Interfaces";
import { formatURL } from "../../utils";

export const DisplayFiles = (props: { files: OrderedFile[] }) => {
  if (props.files == null || props.files.length === 0) return <></>;

  const images = props.files.filter((e: OrderedFile) =>
    e.file_link.match(/.(jpg|jpeg|png|gif)$/i)
  );

  const others = props.files.filter(
    (e: OrderedFile) => !e.file_link.match(/.(jpg|jpeg|png|gif)$/i)
  );

  return (
    <div className="files">
      <div className="images">
        {images &&
          images.map((image, i) => {
            return (
              <img
                src={formatURL(image.file_link)}
                alt={image.file_link}
                className="files-image"
                key={i}
              />
            );
          })}
      </div>

      <div className="docs">
        {others.length !== 0 && <div className="docs-title">Files</div>}
        {others &&
          others.map((file, i) => {
            return (
              <Card
                variant="outlined"
                sx={{ width: 180, height: 180 }}
                key={i}
                style={{ position: "relative" }}
                className="docs-card"
              >
                <CardActionArea
                  sx={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    window.open(formatURL(file.file_link));
                  }}
                >
                  <CardContent className={"docs-card-content"}>
                    <FontAwesomeIcon
                      icon={faFile as IconProp}
                      className="fa-2x"
                    />
                    <Typography variant="body1" className="docs-filename">
                      {file.file_link}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
      </div>
    </div>
  );
};
