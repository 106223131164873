import React from "react";
import { DateRange } from "@mui/lab";

interface ContextInterface {
  elementName: string;
  card: (props: any) => JSX.Element;

  // Content variables
  pageItems: any[];

  // Filter keys
  searchFields: string[];
  searchBySpeakerSeries?: boolean;

  // Filter variables
  searchString: string;
  setSearchString: (s: string) => void;
  startDate: Date | null;
  endDate: Date | null;
  setDates: (r: DateRange<Date>) => void;

  // Pagination variables
  numPerPage: number;
  setNumPerPage: (n: number) => void;
  currPageNum: number;
  setCurrPageNum: (n: number) => void;
  numPages: number;
  setNumPages: (n: number) => void;
}

export const defaultListingContext = {
  elementName: "",
  card: () => <></>,

  pageItems: [],

  searchFields: [],
  searchBySpeakerSeries: false,

  searchString: "",
  setSearchString: () => {},
  startDate: null,
  endDate: null,
  setDates: () => {},

  numPerPage: 0,
  setNumPerPage: () => {},
  currPageNum: 0,
  setCurrPageNum: () => {},
  numPages: 0,
  setNumPages: () => {},
};

export const ListingWithSearchContext = React.createContext<ContextInterface>(
  defaultListingContext
);
