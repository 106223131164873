import { usePublicContext } from "../PublicContext";
import { Loading } from "../../components/Loading/Loading";
import { NoData } from "../../components/NoData";
import { Post } from "../../components/ListingWithSearch/Post/Post";
import { emptySQLNullString } from "../../components/Interfaces/Interfaces";
import parse from "html-react-parser";

export const UpcomingMeetings = () => {
  const { bulletins, sermons } = usePublicContext();

  if (!sermons || !bulletins) {
    return <Loading />;
  }

  const latestPublishedBulletin = sermons
    .filter((x) => x.sermon_date.Valid)
    .sort((a, b) => (a.sermon_date.Time < b.sermon_date.Time ? 1 : -1))
    .map((x) => bulletins[x.id + "-published"])
    .filter((x) => x != null)
    .shift();

  if (!latestPublishedBulletin) {
    return <NoData elementName="upcoming meetings" />;
  }

  return (
    <Post
      date={latestPublishedBulletin.last_updated}
      title="Upcoming Meetings"
      files={[]}
      postImage={emptySQLNullString}
    >
      {parse(latestPublishedBulletin.upcoming_meetings, { trim: true })}
    </Post>
  );
};
