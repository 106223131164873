import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { HeroText } from "../../Hero/Hero";
import { MinistryBanner } from "../../ImageLibrary/imageLibrary";
import { TeamButton } from "../People/TeamSelector";
import "./Ministries.scss";
import { PeopleMinistries } from "./PeopleMinistries/PeopleMinistries";
import { ServingMinistries } from "./ServingMinistries/ServingMinistries";
import { usePageUtils } from "../../usePageUtils";

enum Ministries {
  People,
  Serving,
}

const MinistriesSelector = () => {
  const [ministry, setMinistry] = useState(Ministries.People);
  usePageUtils().setTitleAndGroup("Ministries", "Our Community");

  return (
    <div id="ministries-selector-body">
      <HeroText
        img={MinistryBanner}
        text="Ministries"
        width={4000}
        height={1778}
      />

      <div id="ministries-selector">
        <TeamButton
          onClick={() => setMinistry(Ministries.People)}
          selected={ministry === Ministries.People}
        >
          People
        </TeamButton>
        <TeamButton
          onClick={() => setMinistry(Ministries.Serving)}
          selected={ministry === Ministries.Serving}
        >
          Serving
        </TeamButton>
      </div>

      <TransitionGroup className="transition-container">
        {ministry === Ministries.People && (
          <CSSTransition timeout={700} classNames="page-fade">
            <PeopleMinistries />
          </CSSTransition>
        )}
        {ministry === Ministries.Serving && (
          <CSSTransition timeout={700} classNames="page-fade">
            <ServingMinistries />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default MinistriesSelector;
