import { Wrapper } from "@googlemaps/react-wrapper";
import { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { EmailLink } from "../../components/contact-components/EmailLink/EmailLink";
import { Hero } from "../Hero/Hero";
import { VisitHeroes } from "../ImageLibrary/imageLibrary";
import "./Visit.scss";
import { usePageUtils } from "../usePageUtils";
import { Phone } from "../../components/contact-components/Phone/Phone";

const GoogleMap = () => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      new window.google.maps.Map(ref.current, {
        center: { lat: 1.3170705410691932, lng: 103.91841592712693 },
        mapId: "4551e3c53b74f1a8",
        zoom: 17,
        mapTypeControl: false,
        fullscreenControl: false,
      });
    }
  });

  return <div ref={ref} id="map"></div>;
};

interface GroupDetails {
  time: string;
  name: string;
  link?: string;
  zoom?: string;
}

const MeetingDetails = (props: { title: string; groups: GroupDetails[] }) => {
  return (
    <>
      <h3>{props.title}</h3>
      <table className="meeting-details">
        <tbody>
          {props.groups.map((group, i) => {
            let groupName = <span>{group.name}</span>;
            if (group.link) {
              groupName = <Link to={group.link}>{group.name}</Link>;
            }

            return (
              <tr key={i}>
                <th>{group.time}</th>
                <td>
                  {groupName}{" "}
                  {group.zoom ? (
                    <span>
                      (Join the Zoom link <a href={group.zoom}>here</a>)
                    </span>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const VisitUs = () => {
  usePageUtils().setTitleAndGroup("Visit Us", "General");

  return (
    <>
      <Hero img={VisitHeroes} width={2251} height={678} />
      <div id="visit-us">
        <div id="visit-us-service">
          <h2>Visit Us</h2>
          <p>
            We’ll be happy to have you! This page is all about our Sunday
            services, smaller group meetings, and how to get here.
          </p>
          <p>
            You may contact{" "}
            <EmailLink to="bfec" subject="Enquiry on service details" /> for
            details. Our services are also delayed-telecast at{" "}
            <Link to="/get-plugged-in/sermons">
              https://bfec.org.sg/get-plugged-in/sermons
            </Link>
            .
          </p>

          <MeetingDetails
            title="Sunday Services"
            groups={[
              { time: "8.00am", name: "English service" },
              { time: "9.30am", name: "Chinese service" },
              {
                time: "9.45am",
                name: "Kindergarten Sunday (3-6 year olds) and Junior Sunday School (7-12 year olds)",
                link: "/our-community/ministries#kids",
              },
              { time: "10.00am", name: "English service" },
              {
                time: "10.00am",
                name: "International Fellowship (Indonesian and Filipino)",
                link: "/our-community/ministries#international-fellowship",
              },
              {
                time: "12.00pm",
                name: "Youth service",
                link: "/our-community/ministries#youth",
              },
            ]}
          />

          <MeetingDetails
            title="Monday"
            groups={[
              {
                time: "10.00am",
                name: "40:31 Ministry meeting",
                link: "/our-community/ministries#seniors",
              },
            ]}
          />

          <MeetingDetails
            title="Tuesday"
            groups={[
              {
                time: "8.00pm",
                name: "Ladies’ Ministry meeting",
                link: "/our-community/ministries#ladies",
              },
            ]}
          />

          <MeetingDetails
            title="Wednesday"
            groups={[
              {
                time: "8.30am",
                name: "Midweek Prayer Pause",
                zoom: "https://bfec.sg/prayerpause",
              },
              {
                time: "10.30am",
                name: "Chinese Evergreen Fellowship",
                link: "/our-community/ministries#evergreen",
              },
              {
                time: "7.30pm",
                name: "Jesus Club meeting",
                link: "/our-community/ministries#jesus-club",
              },
            ]}
          />

          <MeetingDetails
            title="Thursday"
            groups={[
              {
                time: "7.45pm",
                name: "Precept-upon-precept Bible Study",
              },
            ]}
          />

          <MeetingDetails
            title="Friday"
            groups={[
              {
                time: "8:00pm",
                name: "Monthly Prayer Meeting (1st Friday of each month)",
              },
            ]}
          />

          <MeetingDetails
            title="Saturday"
            groups={[
              {
                time: "8.00am",
                name: "Limousine Ministry",
                link: "/our-community/ministries#limousine",
              },
            ]}
          />
        </div>
        <div id="visit-us-details">
          <h2>Office Hours</h2>
          <h3>Church</h3>
          <table>
            <tbody>
              <tr>
                <th>Monday&ndash;Friday</th>
                <td>9:00am &ndash; 6:00pm</td>
              </tr>
              <tr>
                <th>Saturday</th>
                <td>9:00am &ndash; 1:00pm</td>
              </tr>
              <tr>
                <th>Sunday</th>
                <td>8:00am &ndash; 1:00pm</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3>Kindergarten</h3>
          <table>
            <tbody>
              <tr>
                <th>Monday&ndash;Friday</th>
                <td>8:00am &ndash; 3:00pm</td>
              </tr>
            </tbody>
          </table>
          <h2>Contact Details</h2>
          <h3>Church</h3>
          <table>
            <tbody>
              <tr>
                <th>Address</th>
                <td>4 La Salle Street, Singapore 456930</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  <EmailLink to="bfec" />
                </td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>
                  <Phone />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3>Kindergarten</h3>
          <table>
            <tbody>
              <tr>
                <th>Address</th>
                <td>2 La Salle Street, Singapore 456929</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  <EmailLink to="bkk" />
                </td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>
                  <Phone num="+65 6442 2622" />
                </td>
              </tr>
            </tbody>
          </table>
          <h2>How to get here</h2>
          <Wrapper apiKey="AIzaSyD3bF5rUHIv6DuP7X5R-pDf_pHbk8Yw_HA">
            <GoogleMap />
          </Wrapper>
          <p>If you are coming by public transport, you may take:</p>
          <ul>
            <li>Bus 42 or 135 from Opp Kembangan MRT station</li>
            <li>Bus 32 from Bedok Interchange (next to Bedok MRT station)</li>
          </ul>
          <p>
            If you are driving, parking is available at Kampung Siglap: <br />
            10 La Salle Street
            <br />
            Singapore 456933
          </p>
          {/* <h2>Ministry Heads</h2>
          <ul>
            <li>
              Children’s Ministry:{" "}
              <a href="mailto:jacqueline.woon@bfec.org.sg">
                jacqueline.woon@bfec.org.sg
              </a>
            </li>
            <li>
              Digital and Creative Outreach:{" "}
              <a href="mailto:julienne.ng@bfec.org.sg">julienne.ng@bfec.org.sg</a>
            </li>
            <li>
              Worship and Service:{" "}
              <a href="mailto:sharon.ngoo@bfec.org.sg">sharon.ngoo@bfec.org.sg</a>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
};
