import { PeopleData } from "./PeopleData";
import { useContext } from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { PeopleContext } from "./PeopleContext";

export const PeopleCard = (props: { data: PeopleData }) => {
  const person = props.data;
  const mobile = useMediaQuery("(min-width:720px)");
  const ctx = useContext(PeopleContext);

  return (
    <div className="people-card">
      <Card
        elevation={0}
        sx={{
          maxWidth: "300px",
          maxHeight: "300px",
          height: "auto",
          width: "auto",
        }}
      >
        <CardActionArea onClick={() => ctx.setModalPerson(person)}>
          <CardContent>
            <Stack
              direction="column"
              alignItems={"center"}
              alignContent={"center"}
            >
              <Avatar
                alt={person.name}
                src={person.image}
                sx={{
                  width: mobile ? "200px" : "95px",
                  height: mobile ? "200px" : "95px",
                  marginBottom: "15px",
                }}
              />
              <div className="people-card-name">{person.name}</div>
              <div className="people-card-role">{person.role}</div>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};
