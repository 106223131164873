import { SpeakerSeriesData } from "../../Interfaces/Interfaces";
import { Link, useLocation } from "react-router-dom";

export const SearchPanel = (props: {
  speakers: SpeakerSeriesData[];
  series: SpeakerSeriesData[];
  selIdx: number;
  hideSearch: () => void;
}) => (
  <div className="search-panel">
    <SearchList
      title="Speakers"
      queryPath="speaker"
      items={props.speakers}
      selIdx={props.selIdx}
      hideSearch={props.hideSearch}
    />
    <SearchList
      title="Series"
      queryPath="series"
      items={props.series}
      selIdx={props.selIdx - props.speakers.length}
      hideSearch={props.hideSearch}
    />
  </div>
);

const SearchList = (props: {
  title: string;
  queryPath: string;
  items: SpeakerSeriesData[];
  selIdx: number;
  hideSearch: () => void;
}) => {
  if (props.items.length === 0) return <></>;

  return (
    <div className="search-list">
      <div className="list-title">{props.title}</div>
      <SearchListItems
        title={props.title}
        queryPath={props.queryPath}
        items={props.items}
        selIdx={props.selIdx}
        hideSearch={props.hideSearch}
      />
    </div>
  );
};

const SearchListItems = (props: {
  title: string;
  queryPath: string;
  items: SpeakerSeriesData[];
  selIdx: number;
  hideSearch: () => void;
}) => (
  <>
    {props.items.map((x, i) => (
      <SearchListItem
        queryPath={props.queryPath}
        item={x}
        key={x.id}
        selected={props.selIdx === i}
        hideSearch={props.hideSearch}
      />
    ))}
  </>
);

const SearchListItem = (props: {
  queryPath: string;
  item: SpeakerSeriesData;
  selected: boolean;
  hideSearch: () => void;
}) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={pathname + "?" + props.queryPath + "=" + props.item.id}
      className={
        "search-list-item" +
        (props.selected ? " search-list-item-selected" : "")
      }
      onClick={props.hideSearch}
    >
      {props.item.name}
    </Link>
  );
};
