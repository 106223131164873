import { BKKFullDayNoticeBanner, BKKWongLPIMG } from "../ImageLibrary/imageLibrary";

export const PrincipalsMessage = () => (
  <div className="red">
    <div className="bkk-ad">
      <img alt="BKK Full Day Notice" src={BKKFullDayNoticeBanner}/>
    </div>
    <div id="principals-message" className="content-holder">
      <img alt="BKK Principal Wong Lee Pin" src={BKKWongLPIMG} width={"100%"} />
      <div id="principals-message-content">
        <h2>Letter from our Principal</h2>
        <p>Dear Parents/Guardians,</p>
        <p>
          We acknowledge that the pre-school years are a critical period for the
          development of children. The quality of time spent at kindergarten is
          crucial to future success in school. They help build a foundation for
          our children’s future, their development in later school years and
          their contribution to building the society of the future. The years of
          early development establish the foundations for learning emotionally,
          socially, spiritually, physically and intellectually.
        </p>
        <p>
          Young children begin learning in a variety of environments before they
          enter kindergarten – in their homes, in playgroup programs and in the
          community. They bring varied levels of knowledge to any learning
          situation based on their environment. When our program helps them to
          explore the world around them and guide them through the transition
          from home to school, they benefit greatly. Over the years, we have
          made refinements to our curriculum to meet the objective of nurturing
          a well-rounded development of our children.
        </p>
        <p>
          We invite you to take a look at our website and find out more about
          our programme and visit us at our Kindergarten.
        </p>
        <div id="sign-off">
          Wong Lee Pin (Ms)
          <div className="subtitle">
            Principal, Bethesda (Katong) Kindergarten
          </div>
        </div>
      </div>
    </div>
  </div>
);
