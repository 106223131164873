import { MinistryDataItem } from "./MinistriesData";
import { MeetDetail } from "./MeetDetail";
import {
  faClock,
  faLocationDot,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { Verse } from "../../../../components/Verse/Verse";
import parse from "html-react-parser";
import { EmailLink } from "../../../../components/contact-components/EmailLink/EmailLink";
import { Link } from "react-router-dom";
import { Phone } from "../../../../components/contact-components/Phone/Phone";

export const MinistryBody = (props: { data: MinistryDataItem }) => {
  const data = props.data;

  return (
    <div className="ministry-text">
      {data.details?.map((deet, i) => {
        return (
          <div className="min-details" key={i}>
            <h3>{deet.title}</h3>
            {(deet.time || deet.age || deet.location) && (
              <div className="meet-details">
                {deet.time && <MeetDetail icon={faClock} text={deet.time} />}
                {deet.age && <MeetDetail icon={faUser} text={deet.age} />}
                {deet.location && (
                  <MeetDetail icon={faLocationDot} text={deet.location} />
                )}
              </div>
            )}
            {deet.preverse != null && (
              <Verse verseRef={deet.preverse.ref}>{deet.preverse.verse}</Verse>
            )}
            {deet.mission != null && (
              <div className="mission">
                <h4>Mission</h4>
                {parse(deet.mission)}
              </div>
            )}
            {deet.vision != null && (
              <div className="vision">
                <h4>Vision</h4>
                {parse(deet.vision)}
              </div>
            )}
            <div className="min-text">
              {parse(deet.content)}
              {deet.contact != null && deet.contact.type !== undefined && (
                <>
                  {deet.contact.message != null ? (
                    <p className="contact">
                      {deet.contact.message}
                      {deet.contact.type === "email" && (
                        <EmailLink to={deet.contact.addr} />
                      )}
                      {deet.contact.type === "phone" && (
                        <Phone num={deet.contact.addr} />
                      )}
                      {deet.contact.type === "link" && (
                        <Link to={deet.contact.addr}>here</Link>
                      )}
                      {deet.contact.messageEnd}
                    </p>
                  ) : (
                    <p className="contact">
                      Contact <EmailLink to={deet.contact.addr} /> to sign up!
                    </p>
                  )}
                </>
              )}
            </div>
            {/* <div className="contact"></div> */}
          </div>
        );
      })}
    </div>
  );
};
