import { KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import { ListingWithSearchContext } from "../ListingWithSearchContext";
import "./Filters.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { SearchPanel } from "./SearchPanel";
import { CSSTransition } from "react-transition-group";
import { SpeakerSeriesData } from "../../Interfaces/Interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { usePublicContext } from "../../../public/PublicContext";
import { useAdminDataContext } from "../../../admin/AdminDataContext";

const filter = (items: SpeakerSeriesData[] | null, searchString: string) => {
  if (items == null || searchString.length === 0) return [];
  const pattern = new RegExp(searchString, "i");
  return items.filter((x) => pattern.test(x.name));
};

export const Filters = (props: { newAction?: string | (() => void) }) => {
  const publicContext = usePublicContext();
  const adminContext = useAdminDataContext();

  const speakers = publicContext.speakers || adminContext.speakers;
  const series = publicContext.series || adminContext.series;

  const searchCtx = useContext(ListingWithSearchContext);
  const [showFilters, setShowFilters] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const ref = useRef<any>(null);
  const searchInputRef = useRef<any>(null);
  const [selIdx, setSelIdx] = useState(-1);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const filteredSpeakers = filter(speakers, searchCtx.searchString);
  const filteredSeries = filter(series, searchCtx.searchString);

  const hideSearch = () => {
    setShowFilters(false);
    setShowPanel(false);
    searchCtx.setSearchString("");
  };

  useEffect(() => {
    if (showFilters) {
      searchInputRef.current.focus();
    } else {
      searchInputRef.current.blur();
    }
  }, [showFilters]);

  useEffect(() => {
    if (searchCtx.searchString.length === 0) {
      setShowPanel(false);
      return;
    }

    if (!showFilters) {
      // Indicates that the page is just loaded, since we have a search string
      // but filter is not shown.
      setShowFilters(true);
    }

    if (!searchCtx.searchBySpeakerSeries) {
      setShowPanel(false);
      return;
    }

    // No results found
    if (filteredSpeakers.length === 0 && filteredSeries.length === 0) {
      setShowPanel(false);
      return;
    }

    // Else, show panel
    setShowPanel(true);
    setSelIdx(-1);
  }, [
    searchCtx.searchBySpeakerSeries,
    searchCtx.searchString,
    filteredSeries.length,
    showFilters,
    filteredSpeakers.length,
  ]);

  useEffect(() => {
    function handleOutsideClick(e: Event) {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowPanel(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [ref]);

  const checkKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape" || e.keyCode === 27) {
      hideSearch();
      return;
    }

    if (e.key === "ArrowDown" || e.keyCode === 40) {
      setSelIdx((x) =>
        Math.min(x + 1, filteredSeries.length + filteredSpeakers.length - 1)
      );
      return;
    }

    if (e.key === "ArrowUp" || e.keyCode === 38) {
      setSelIdx((x) => Math.max(x - 1, 0));
      return;
    }

    if (e.key === "Enter" || e.keyCode === 13) {
      if (selIdx === -1) return;
      if (selIdx < filteredSpeakers.length) {
        navigate(pathname + "?speaker=" + filteredSpeakers[selIdx].id);
      } else {
        navigate(
          pathname +
            "?series=" +
            filteredSeries[selIdx - filteredSpeakers.length].id
        );
      }
      hideSearch();
    }
  };

  return (
    <div className="filters" ref={ref}>
      <div className="search-input">
        <CSSTransition in={showFilters} timeout={250} classNames="horizontal">
          <input
            type="text"
            placeholder="Search"
            value={searchCtx.searchString}
            onChange={(e) => {
              const sanitized = e.target.value.replaceAll(/[/\\*+()?[\]]/g, "");
              searchCtx.setSearchString(sanitized);
            }}
            onKeyDown={(e) => checkKeyPress(e)}
            ref={searchInputRef}
          />
        </CSSTransition>
      </div>
      <div
        className="listing-filters-btn"
        onClick={() => {
          if (showFilters) {
            hideSearch();
          } else {
            setShowFilters(true);
          }
        }}
        title={showFilters ? "Cancel search" : "Search"}
      >
        <FontAwesomeIcon
          icon={showFilters ? faTimes : faMagnifyingGlass}
          fixedWidth
        />
      </div>

      {props.newAction && (
        <Button
          variant="contained"
          onClick={() => {
            if (typeof props.newAction === "string") {
              navigate(props.newAction);
            } else if (props.newAction) {
              props.newAction();
            }
          }}
        >
          New
        </Button>
      )}

      <CSSTransition
        in={showFilters && showPanel}
        timeout={700}
        classNames="component-fade"
      >
        <SearchPanel
          speakers={filteredSpeakers}
          series={filteredSeries}
          selIdx={selIdx}
          hideSearch={hideSearch}
        />
      </CSSTransition>
    </div>
  );
};
