import {
  SermonInterface,
  SpeakerSeriesData,
} from "../../components/Interfaces/Interfaces";
import {
  faBible,
  faMemo,
  faPresentationScreen,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { getPathPrefix } from "../../utils";

const SermonMeta = (props: {
  icon: IconProp;
  children: JSX.Element | JSX.Element[] | string;
}) => (
  <div className="meta">
    <FontAwesomeIcon icon={props.icon} fixedWidth />
    {props.children}
  </div>
);

const SermonMetaExternalLink = (props: {
  icon: IconProp;
  text: string;
  link: string;
}) => {
  if (props.link === "") {
    return <></>;
  }

  return (
    <SermonMeta icon={props.icon}>
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        {props.text}
      </a>
    </SermonMeta>
  );
};

const SermonMetaSpeakers = (props: { speakers: SpeakerSeriesData[] }) => {
  const { pathname } = useLocation();
  const path = getPathPrefix(pathname);

  const speakers =
    props.speakers != null ? (
      props.speakers.map((x) => (
        <Link
          key={x.id}
          to={path + "?speaker=" + x.id}
          className="sermon-meta-speaker"
        >
          {x.name}
        </Link>
      ))
    ) : (
      <></>
    );
  return <SermonMeta icon={faUser}>{speakers}</SermonMeta>;
};

const SermonMetaPassage = (props: { passage: string }) => {
  if (props.passage === "") return <></>;
  return <SermonMeta icon={faBible}>{props.passage}</SermonMeta>;
};

export const SermonInformation = (props: { sermon: SermonInterface }) => (
  <div id="sermon-post-information-meta">
    <SermonMetaSpeakers speakers={props.sermon.speaker} />
    <SermonMetaPassage passage={props.sermon.main_passage} />
    <SermonMetaExternalLink
      icon={faMemo}
      text="Bulletin"
      link={props.sermon.bulletin}
    />
    <SermonMetaExternalLink
      icon={faPresentationScreen}
      text="Sermon Slides"
      link={props.sermon.sermon_notes}
    />
  </div>
);
