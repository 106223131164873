import {
  faBible,
  faCross,
  faHandHoldingSeedling,
  faInfinity,
  faLifeRing,
  faLock,
  faPerson,
  faWind,
} from "@fortawesome/pro-thin-svg-icons";
import { BtnsWithInfo } from "./BtnsWithInfo";

export const WhatWeBelieve = () => (
  <BtnsWithInfo
    header="What do we believe?"
    items={[
      {
        icon: faHandHoldingSeedling,
        name: "About God",
        content: (
          <>
            <p>
              God is the Creator and Ruler of the universe. He has eternally
              existed in three persons: the Father, the Son and the Holy Spirit.
              These three are co-equal and are one God.
            </p>
            <p>
              Genesis 1:1, 26, 27; 3:22; Psalm 90:2; Matthew 28:19; 1 Peter 1:2;
              2 Corinthians 13:14
            </p>
          </>
        ),
      },
      {
        icon: faCross,
        name: "About Jesus Christ",
        content: (
          <>
            <p>
              Jesus Christ is the Son of God. He is co-equal with the Father.
              Jesus lived a sinless human life and offered Himself as the
              perfect sacrifice for the sins of all people by dying on a cross.
              He arose from the dead after three days to demonstrate His power
              over sin and death. He ascended to Heaven’s glory and will return
              again someday to earth to reign as King of Kings and Lord of
              Lords.
            </p>
            <p>
              Matthew 1:22–23; Isaiah 9:6; John 1:1–5; 14:10–30; Hebrews
              4:14–15; 1 Corinthians 15:3–4; Romans 1:3–4; Acts 1:9–11; 1
              Timothy 6:14–15; Titus 2:13
            </p>
          </>
        ),
      },
      {
        icon: faWind,
        name: "About the Holy Spirit",
        content: (
          <>
            <p>
              The Holy Spirit is co-equal with the Father and the Son of God. He
              is present in the world to make men aware of their need for Jesus
              Christ. He also lives in every Christian from the moment of
              salvation. He provides the Christian with power for living,
              understanding of spiritual truth and guidance in doing what is
              right. He gives every believer a spiritual gift when they are
              saved. As Christians, we seek to live under His control daily.
            </p>
            <p>
              2 Corinthians 3:17; John 16:7–13; 14:16–17; Acts 1:8; 1
              Corinthians 2:12; 3:16; Ephesians 1:13; Galatians 5:25; Ephesians
              5:18
            </p>
          </>
        ),
      },
      {
        icon: faBible,
        name: "About the Bible",
        content: (
          <>
            <p>
              The Bible is God’s Word to us. It was written by human authors,
              under the supernatural guidance of the Holy Spirit. It is the
              supreme source of truth for Christian beliefs and living. Because
              it is inspired by God, it is the truth without any mixture of
              error.
            </p>
            <p>
              2 Timothy 3:16; 2 Peter 1:20–21; 2 Timothy 1:13; Psalm 119:105;
              Proverbs 30:5
            </p>
          </>
        ),
      },
      {
        icon: faPerson,
        name: "About Human Beings",
        content: (
          <>
            <p>
              People are made in the spiritual image of God, to be like Him in
              character. People are the supreme object of God’s creation.
              Although every person has tremendous potential for good, all of us
              are marred by an attitude of disobedience toward God called “sin.”
              This attitude separates people from God and causes many problems
              in life.
            </p>
            <p>
              Genesis 1:27; Psalm 8:3–6; Isaiah 53:6a; Romans 3:23; Isaiah
              59:1–2
            </p>
          </>
        ),
      },
      {
        icon: faLifeRing,
        name: "About Salvation",
        content: (
          <>
            <p>
              Salvation is God’s free gift to us but we must accept it. We can
              never make up for our sin by self-improvement or good works. Only
              by trusting in Jesus Christ as God’s offer of forgiveness can
              anyone be saved from sin’s penalty. When we turn from our
              self-ruled life and turn to Jesus in faith we are saved. Eternal
              life begins the moment one receives Jesus Christ into his life by
              faith.
            </p>
            <p>
              Romans 6:23; Ephesians 2:8–9; John 1:12; 14:6; Titus 3:5;
              Galatians 3:26; Romans 5:1
            </p>
          </>
        ),
      },
      {
        icon: faLock,
        name: "About Eternal Security",
        content: (
          <>
            <p>
              Because God gives us eternal life through Jesus Christ, the true
              believer is secure in that salvation for eternity. If you have
              been genuinely saved, you cannot “lose” it. Salvation is
              maintained by the grace and power of God, not by the self-effort
              of the Christian. It is the grace and keeping power of God that
              gives us this security.
            </p>
            <p>
              John 10:29; 2 Timothy 1:12; Hebrews 7:25; 10:10, 14; 1 Peter 1:3–5
            </p>
          </>
        ),
      },
      {
        icon: faInfinity,
        name: "About Eternity",
        content: (
          <>
            <p>
              People were created to exist forever. We will either exist
              eternally separated from God by sin, or eternally with God through
              forgiveness and salvation. To be eternally separated from God is
              Hell. To be eternally in union with Him is eternal life. Heaven
              and Hell are real places of eternal existence.
            </p>
            <p>
              John 3:16; 14:17; Romans 6:23; 8:17–18; Revelation 20:15; 1
              Corinthians 2:7–9
            </p>
          </>
        ),
      },
    ]}
  />
);
