import { Route } from "react-router-dom";
import { SermonsContainer } from "./SermonsContainer";
import { PostsListing } from "../../components/ListingWithSearch/PostsListing/PostsListing";
import { SermonPost } from "./SermonPost";

export const buildSermonsRoute = () => (
  <Route path="sermons" element={<SermonsContainer />}>
    <Route path="" element={<PostsListing />} />
    <Route path=":slug" element={<SermonPost />} />
  </Route>
);
