import { Link, useLocation } from "react-router-dom";
import { usePublicContext } from "../../../public/PublicContext";
import { SpeakerSeriesData } from "../../Interfaces/Interfaces";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { SeriesImage } from "../../SeriesImage";

export const FilterBlurb = () => {
  const { search, pathname } = useLocation();
  const { series, speakers } = usePublicContext();

  if (search === "") return <></>;

  const [key, id] = search.substring(1).split("=");
  const idInt = parseInt(id);
  let data: SpeakerSeriesData | undefined;

  switch (key) {
    case "speaker":
      data = speakers?.find((x) => x.id === idInt);
      break;
    case "series":
      data = series?.find((x) => x.id === idInt);
      break;
  }

  if (!data) return <></>;

  return (
    <div className="filter-blurb-container">
      {key === "series" && <SeriesImage data={data.image_link} />}
      <div className="filter-blurb">
        <div className="row">
          <h2>{data.name}</h2>
          <Link to={pathname} className="close-btn">
            <FontAwesomeIcon icon={faTimes} />
          </Link>
        </div>
        <div className="filter-blurb-text">{parse(data.content)}</div>
      </div>
    </div>
  );
};
