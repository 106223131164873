import {
  GeneralPaynowImage,
  MissionsPaynowImage,
} from "./ImageLibrary/imageLibrary";
import { usePageUtils } from "./usePageUtils";

export const Giving = () => {
  usePageUtils().setTitleAndGroup("Giving", "General");

  return (
    <div>
      <div className="content-holder">
        <h1>Giving</h1>
        <GivingContent />
      </div>
    </div>
  );
};

export const GivingContent = () => (
  <div>
    <p>
      Giving as “unto the Lord” is the privilege of every disciple of Christ. As
      an act of personal & corporate worship, we encourage members to
      participate purposefully through regular giving during worship services.
    </p>
    <p>
      1 Cor 16 and 2 Cor 8–9 tell us that we should give to the Lord regularly
      and in love, generously by faith, cheerfully to meet needs,
      proportionately based on what one has.
    </p>
    <p>
      During this Covid situation, we are not able to pass around our usual red
      and blue offering bags, so here are various ways you can give:
    </p>
    <h3>Internet Banking</h3>
    <p>
      Bank Code: <strong>7375</strong> <br />
      General & Ministry Fund: <strong>UOB 451-302-912-2</strong> <br />
      Missions Fund: <strong>UOB 451-302-915-7</strong> <br />
    </p>
    <h3>Cheque</h3>
    <p>
      If you wish to send a cheque, it should be made out to:{" "}
      <strong>Bethesda Frankel Estate Church. </strong>
    </p>
    <p>
      Please help to drop your cheque directly into any UOB branch’s “Cheque
      Drop In” box. Please write down on the reverse side of the cheque:
      <strong> Bethesda Frankel Estate Church </strong> and indicate the
      respective account number:
    </p>
    <p>
      General & Ministry Fund: <strong>UOB 451-302-912-2</strong> <br />
      Missions Fund: <strong>UOB 451-302-915-7</strong> <br />
    </p>
    <h3>PayNow</h3>
    <p>
      General & Ministry Fund UEN: <strong>197302391EA01</strong> <br />
      Missions Fund UEN: <strong>197302391EA02</strong> <br />
    </p>
    <figure className="tithing-paynow">
      <img src={GeneralPaynowImage} alt="General PayNow QR Code" />
      <img src={MissionsPaynowImage} alt="Missions PayNow QR Code" />
    </figure>
  </div>
);
