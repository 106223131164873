import {
  faBible,
  faUser,
  faUsers,
  faDiagramNested,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SermonInterface } from "../../components/Interfaces/Interfaces";
import { SpeakerAvatar } from "../../components/SpeakerAvatar/SpeakerAvatar";
import { Tag } from "../../components/Tag/Tag";
import { prettyDate } from "../../utils";
import { Link, useLocation } from "react-router-dom";
import { ampli } from "../../ampli";

export const SermonCard = (props: { data: SermonInterface }) => {
  const { slug, speaker, sermon_date, series, title, main_passage, tags } =
    props.data;
  const { pathname } = useLocation();

  if (!sermon_date.Time) return <></>;

  const speakerNames =
    props.data.speaker != null ? (
      props.data.speaker.map((x) => (
        <Link
          key={x.id}
          to={pathname + "?speaker=" + x.id}
          className="card-details-speaker"
          onClick={() => ampli.viewedSpeaker({ Name: x.name })}
        >
          {x.name}
        </Link>
      ))
    ) : (
      <></>
    );
  const services =
    props.data.service_data != null
      ? props.data.service_data
          .map((x) => x.service.name.split(" ")[0])
          .join(", ")
      : [];
  const dateText = prettyDate(sermon_date.Time) + " / " + services;
  const link = pathname + "/" + slug;

  return (
    <div className="card sermon-card">
      <div className="card-content">
        <Link to={link} style={{ textDecoration: "none" }}>
          <SpeakerAvatar speaker={speaker} />
        </Link>
        <div className="card-text">
          <div className="card-date date">{dateText}</div>
          <div className="card-title">
            <Link to={link}>{title}</Link>
          </div>

          {series.name && (
            <div className="card-details series">
              <FontAwesomeIcon icon={faDiagramNested} fixedWidth />
              <Link
                to={pathname + "?series=" + series.id}
                onClick={() => ampli.viewedSeries({ Name: series.name })}
              >
                {series.name}
              </Link>
            </div>
          )}

          <div className="card-details">
            {props.data.speaker != null && props.data.speaker.length === 1 && (
              <FontAwesomeIcon icon={faUser} fixedWidth />
            )}
            {props.data.speaker != null && props.data.speaker.length > 1 && (
              <FontAwesomeIcon icon={faUsers} fixedWidth />
            )}
            {speakerNames}
          </div>
          {main_passage && (
            <div className="card-details card-details-passage">
              <FontAwesomeIcon icon={faBible} fixedWidth />
              {main_passage}
            </div>
          )}
          {tags.length > 0 && (
            <div className={"card-details tags"}>
              {tags.map((x) => (
                <Tag key={x.id} tag={x} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
