import { useEffect, useState } from "react";
import "./Hero.scss";

export const Hero = (props: {
  img: string[];
  width: number;
  height: number;
  fullHeight?: boolean; // used for welcome page
  children?: any;
}) => {
  const [img, setImg] = useState("");
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setImg(props.img[Math.floor(Math.random() * props.img.length)]);
  }, [props.img]);

  useEffect(() => {
    const maxHeight = window.innerHeight - (window.innerWidth > 720 ? 120 : 64);

    if (props.fullHeight) {
      setHeight(maxHeight);
    } else {
      const ratioedHeight =
        (document.body.clientWidth / props.width) * props.height;

      setHeight(Math.min(maxHeight, ratioedHeight));
    }
  }, [props.fullHeight, props.height, props.width]);

  let divClass = "hero";
  if (props.children) divClass += " hero-with-children";

  return (
    <div className={divClass} style={{ height: height + "px" }}>
      <img
        src={img}
        alt=""
        className={"hero-image" + (props.fullHeight ? " img-full-height" : "")}
      />
      {props.children != null && (
        <div className="hero-content">{props.children}</div>
      )}
    </div>
  );
};

export const HeroText = (props: {
  img: string[];
  width: number;
  height: number;
  text: string;
  align?: "center" | "top-left" | "top-right" | "bottom-right" | "bottom-left";
  title?: boolean;
}) => (
  <Hero img={props.img} width={props.width} height={props.height}>
    <div
      className={
        "hero-text-container align-" + (props.align ? props.align : "center")
      }
    >
      <div className={"hero-text " + (props.title ? "title" : "")}>
        {props.text}
      </div>
    </div>
  </Hero>
);
