import { ReactElement, useEffect, useState } from "react";
import { ListingWithSearchContext } from "./ListingWithSearchContext";
import { Loading } from "../Loading/Loading";
import "./ListingWithSearch.scss";
import { DateRange } from "@mui/lab";
import { Outlet, useLocation } from "react-router-dom";
import { SermonInterface } from "../Interfaces/Interfaces";
import { ampli } from "../../ampli";

const filterSearchString = (
  searchString: string,
  searchFields: string[],
  items: any[]
) => {
  const pattern = new RegExp(searchString, "i");
  return items.filter((x) => {
    for (const field of searchFields) {
      if (pattern.test(x[field])) return true;
    }
    return false;
  });
};

const filterItems = (
  items: any[],
  searchFields: string[],
  searchString: string,
  queryParams: string
) => {
  let newItems = items;

  if (searchFields.length > 0 && searchString.length > 0) {
    newItems = filterSearchString(searchString, searchFields, newItems);
  }

  if (queryParams !== "") {
    const [key, id] = queryParams.substring(1).split("=");
    const idInt = parseInt(id);

    switch (key) {
      case "speaker":
        newItems = newItems.filter((x: SermonInterface) => {
          const speakerIds =
            x.speaker != null ? x.speaker.map((y) => y.id) : [];
          return speakerIds.includes(idInt);
        });
        break;
      case "series":
        newItems = newItems.filter(
          (x: SermonInterface) => x.series.id === idInt
        );
        break;
    }
  }

  return newItems;
};

interface ListingWithSearchProps {
  elementName: string;
  items: any[] | null;
  card: (props: any) => ReactElement;
  searchFields: string[];
  searchBySpeakerSeries?: boolean;
  children?: ReactElement | ReactElement[];
}

export const ListingWithSearch = ({
  elementName,
  card,
  items,
  searchFields,
  searchBySpeakerSeries,
  children,
}: ListingWithSearchProps) => {
  const { search } = useLocation();
  // Content variables
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [pageItems, setPageItems] = useState<any[]>([]);

  // Filter variables
  const [searchString, setSearchString] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  // Pagination variables
  const [numPerPage, setNumPerPage] = useState(12);
  const [currPageNum, setCurrPageNum] = useState(0);
  const [numPages, setNumPages] = useState(1);

  const setDates = (dates: DateRange<Date>) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  useEffect(() => {
    if (!items) {
      setFilteredItems([]);
    } else {
      const newlyFilteredItems = filterItems(
        items,
        searchFields,
        searchString,
        search
      );

      setFilteredItems(newlyFilteredItems);

      if (searchString.length > 0) {
        ampli.postSearch({
          "Element Name": elementName,
          "Num Results": newlyFilteredItems.length,
          "Search String": searchString,
        });
      }
    }
  }, [elementName, items, search, searchFields, searchString]);

  useEffect(() => {
    // We run this check separately so that
    //we don't need to keep filtering the data
    const startIdx = currPageNum * numPerPage;
    const endIdx = Math.min(filteredItems.length, startIdx + numPerPage);
    setPageItems(filteredItems.slice(startIdx, endIdx));
  }, [filteredItems, currPageNum, numPerPage]);

  useEffect(() => {
    const newNumPages = Math.ceil(filteredItems.length / numPerPage);
    setNumPages(newNumPages);
    if (newNumPages < currPageNum) setCurrPageNum(0);
  }, [currPageNum, filteredItems, numPerPage]);

  useEffect(() => {
    setSearchString("");
  }, [search]);

  if (!items) return <Loading />;

  return (
    <ListingWithSearchContext.Provider
      value={{
        elementName,
        card,

        pageItems,

        searchFields,
        searchBySpeakerSeries,

        searchString,
        setSearchString,
        startDate,
        endDate,
        setDates,

        numPerPage,
        setNumPerPage,
        currPageNum,
        setCurrPageNum,
        numPages,
        setNumPages,
      }}
    >
      <div className="listing-with-search">
        {children}
        <Outlet />
      </div>
    </ListingWithSearchContext.Provider>
  );
};
