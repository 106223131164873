import { useContext } from "react";
import { ListingWithSearchContext } from "../ListingWithSearchContext";
import { SelectDropdown } from "../../Inputs/SelectDropdown";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faEllipsis,
} from "@fortawesome/pro-regular-svg-icons";
import Scroll from "react-scroll";

export const Pagination = () => {
  const ctx = useContext(ListingWithSearchContext);
  return (
    <div className="paginate-stack">
      <div className="per-page">
        <label htmlFor="per-page">Per Page:</label>
        <SelectDropdown
          id="per-page"
          value={ctx.numPerPage}
          update={ctx.setNumPerPage}
          options={[12, 24, 36, 48, 96]}
        />
      </div>
      <ReactPaginate
        previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        breakLabel={<FontAwesomeIcon icon={faEllipsis} />}
        breakClassName={"paginate-break"}
        pageCount={ctx.numPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={(e) => {
          ctx.setCurrPageNum(e.selected);
          Scroll.scroller.scrollTo("listing-top", {
            smooth: true,
            duration: 300,
            isDynamic: true,
            offset: window.innerWidth > 720 ? -130 : -80,
          });
        }}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={ctx.currPageNum}
      />
    </div>
  );
};
