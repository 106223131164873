import { TimelineItem } from "../../components/TimelineItem/TimelineItem";
import { Hero } from "../Hero/Hero";
import { BKKPark } from "../ImageLibrary/imageLibrary";
import Scroll from "react-scroll";

const Award = (props: {
  header: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
}) => (
  <div className="bkk-award">
    <strong>{props.header}</strong>
    {props.children}
  </div>
);

export const BKKAchievements = () => (
  <>
    <Hero img={[BKKPark]} width={4000} height={1800} />
    <Scroll.Element name="achievements" />
    <div className="content-holder">
      <h2>BKK Achievements</h2>
      <p>
        Here are some of the awards and achievements that we have been honoured
        to attain.
      </p>
      <div id="bkk-awards-list">
        <TimelineItem date="2019">
          <Award header="Health Promoting Preschool Award" />
        </TimelineItem>
        <TimelineItem date="2017">
          <Award header="Achieved SPARK accreditation">
            <p>
              The Singapore Pre-school Accreditation Framework, or SPARK,
              recognises preschools for their continued efforts in providing
              quality preschool education.
            </p>
          </Award>
          <Award header="Health Promoting Pre-school certification by HPB">
            <p>
              HPB encourages pre-schools to establish a culture that promotes
              healthy living. We provide healthy meals and help our students
              maintain a healthy lifestyle by encouraging physical activity and
              teaching them about health and nutrition.
            </p>
          </Award>
        </TimelineItem>
        <TimelineItem date="2015">
          <Award
            header={
              <>
                18<sup>th</sup> Kanagawa Biennial World Children's Art Award
              </>
            }
          >
            <p>
              The Kanagawa Biennial World Children’s Art Exhibition has been
              held every two years since 1981. Held in Kanagawa Prefecture
              adjacent to Japan’s capital of Tokyo, this event exhibits
              children’s art from all over the world, and is intended to use the
              power of painting to foster the dreams and creativity of today’s
              young children who will go on to become the leaders of tomorrow.
            </p>
            <p>
              The exhibition is also intended to further understanding of the
              world’s diverse cultures and differences in global lifestyles. We
              are honoured to have our student, Rainer Lim, selected as one of
              the prize-winning works out of more than 20,000 applicants from
              around the world.
            </p>
          </Award>
          <Award header="Reading Innovation Award (Commendation) given by NLB" />
        </TimelineItem>
        <TimelineItem date="2012">
          <Award header="MOE Good Practice Award" />
          <Award header="MOE Peer Sharing" />
          <Award header="MOE Excel Fest" />
        </TimelineItem>
        <TimelineItem date="2011">
          <Award header="MOE Kindergarten Learning Forum" />
          <Award header="MOE Good Practice Award" />
          <Award
            header="MOE–AECES Awards for Kindergarten Excellence (Merit) — “Art All
          Around.”"
          />
        </TimelineItem>
        <TimelineItem date="2010">
          <Award header="MOE Excel Fest" />
          <Award header="MOE Good Practice Award" />
        </TimelineItem>
        <TimelineItem date="2009">
          <Award header="MOE Kindergarten Learning Forum" />
          <Award
            header="MOE–AECES Awards for Kindergarten Excellence (Merit) —
            “Creating A Language-Rich Environment.”"
          />
        </TimelineItem>
      </div>
    </div>
  </>
);
