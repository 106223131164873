import { PeopleData } from "./PeopleData";
import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import parse from "html-react-parser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "400px",
  maxHeight: "80%",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const PersonModal = (props: {
  close: () => void;
  person: PeopleData | null;
}) => (
  <Modal open={props.person !== null} onClose={props.close}>
    <Card sx={style}>
      <CardContent>
        {props.person !== null && (
          <Stack direction="column" alignItems={"center"}>
            <IconButton
              onClick={props.close}
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
                width: "35px",
                height: "35px",
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
            <Avatar
              alt={props.person.name}
              src={props.person.image}
              sx={{ width: 250, height: 250, marginBottom: "20px" }}
            />
            <Typography variant="h6" fontWeight={"bold"}>
              {props.person.name}
            </Typography>
            <div style={{ overflow: "auto" }}>
              <Typography variant="subtitle1">
                {parse(props.person.content)}
              </Typography>
            </div>
          </Stack>
        )}
      </CardContent>
    </Card>
  </Modal>
);
