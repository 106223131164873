import { Hero } from "../Hero/Hero";
import {
  BKKBlocks,
  BKKChef,
  BKKConcert,
  BKKPainting1,
  BKKPainting2,
  BKKPlay,
  BKKPlayground,
} from "../ImageLibrary/imageLibrary";
import { TimelineItem } from "../../components/TimelineItem/TimelineItem";
import { BKKNurseryK1K2 } from "./BKKNurseryK1K2";
import { BKKSpecialActivities } from "./BKKSpecialActivities";
import Scroll from "react-scroll";

export const BKKProgramme = () => (
  <>
    <Hero img={[BKKBlocks]} width={1800} height={900} />
    <Scroll.Element name="programme" />
    <div className="content-holder">
      <h2>Our Programme</h2>
      <h3>Session/Hours</h3>
      <p>
        We operate 1 session from 8.15am to 12.15pm.
        <br />
        Optional enrichment classes are available in the afternoons.
      </p>
      <h3>Types of Classes</h3>
      <div id="bkk-class-types">
        <TimelineItem date="From 18 months">Playgroup</TimelineItem>
        <TimelineItem date="3 years old">Pre-Nursery</TimelineItem>
        <TimelineItem date="4 years old">Nursery</TimelineItem>
        <TimelineItem date="5 years old">K1</TimelineItem>
        <TimelineItem date="6 years old">K2</TimelineItem>
      </div>
    </div>
    <Hero img={[BKKPainting1, BKKPainting2]} width={1800} height={1100} />
    <div className="red">
      <div className="content-holder">
        <h3>Curriculum</h3>
        <p>
          We adopt an integrated curriculum that supports children’s holistic
          development and artistic and creative potential.
        </p>
        <h4>STELLAR Approach to Teaching</h4>
        <p>
          Based on our Innovation Project 2009 awarded by MOE-AECES, we are
          adapting the STELLAR (Strategies for English Language Learning and
          Reading) Approach to teaching by integrating the four language skills
          of listening, speaking, reading and writing through the provision of a
          “Language-Rich Environment.”
        </p>
        <h4>Project: “Art All Around.”</h4>
        <p>
          We believe that art experiences are of much value to all children.
          Hence, we provide opportunities to heighten children’s perception and
          interest in art and art forms. Children are exposed to art through
          direct experience, verbal and visual stimulation and are provided a
          variety of developmentally appropriate art materials and writing
          tools.
        </p>
        <p>
          The learning areas are integrated into the art activities. We adopt
          the “Stellar Approach to Teaching” to describe the children’s art and
          craft work. This nurtures learning dispositions in children eg the
          valuable disposition of mutual appreciation stands out when children
          give positive comments on the peer’s artwork.
        </p>
      </div>
    </div>
    <Hero img={[BKKPlayground]} width={3000} height={2000} />
    <div className="content-holder">
      <h4>Playgroup and Pre-Nursery</h4>
      <p>
        The Playgroup and Pre-Nursery programmes are more flexible. Our
        Thematic-based curriculum features music and movement, art and craft,
        circle time, story time, language arts, numeracy, outdoor activities,
        water and sand play, dramatic play, fun-way activities of learning
        Mandarin and manipulative/construction play. Our emphasis on Character
        Education develops and nurtures the children.
      </p>
    </div>

    <Hero img={[BKKPlay]} width={3000} height={2000} />
    <div className="content-holder">
      <h4>Nursery, K1 and K2</h4>
      <BKKNurseryK1K2 />
    </div>
    <Hero img={[BKKChef]} width={1024} height={650} />
    <div className="content-holder">
      <h4>Special Activities</h4>
      <BKKSpecialActivities />
    </div>
    <Hero img={[BKKConcert]} width={1024} height={650} />
  </>
);
