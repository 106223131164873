import { EmailLink } from "../../../../components/contact-components/EmailLink/EmailLink";

export const ServingMinistries = () => (
  <div id="serving-ministries">
    <h1>Serving Ministries</h1>
    <p>
      To find out how you can serve in BFEC with your unique gifts, contact the
      following people:
    </p>

    <div className="ministry-boxes">
      <ServingMinistry
        name="Worship and Service"
        blurb="Make a joyful noise unto the Lord!"
        contact="sharon.ngoo@bfec.org.sg"
      />
      <ServingMinistry
        name="Tech Crew"
        blurb="Lights, camera, all kinds of action behind the scenes!"
        contact="johnteo@bfec.org.sg"
      />
      <ServingMinistry
        name="Digital and Creative Outreach"
        blurb="Art, social media, and outreach of the offbeat kind"
        contact="julienne.ng@bfec.org.sg"
      />
      <ServingMinistry
        name="Flower Arrangement"
        blurb="Fresh blooms as an offering of beauty for the Lord’s Day"
        contact="liewester7@gmail.com"
      />
      <ServingMinistry
        name="Feed5K"
        blurb="Social concern in Jesus’s footsteps"
        contact="feed5k@bfec.org.sg"
      />
      <ServingMinistry
        name="Ukulele Group"
        blurb="Learn to strum in harmony with our ukulele choir!"
        contact="francissohpc@gmail.com"
      />
    </div>
  </div>
);

const ServingMinistry = (props: {
  name: string;
  blurb: string;
  contact: string;
}) => (
  <div className="ministry-box">
    <div className="ministry-name">{props.name}</div>
    <div className="ministry-blurb">{props.blurb}</div>
    <div className="ministry-contact">
      <EmailLink to={props.contact} />
    </div>
  </div>
);
