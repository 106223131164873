import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";

export const BKKParentsPortalLink = () => (
  <div className="red">
    <div className="content-holder" id="bkk-parents-portal-cta">
      <h2>Already a parent with us?</h2>
      <a
        href="https://parent.focusit.sg/bkk/parent_login.php?redirect=/bkk/index.php"
        className="btn btn-primary"
      >
        Go to the parents’ portal
        <FontAwesomeIcon icon={faExternalLink} />
      </a>
    </div>
  </div>
);
