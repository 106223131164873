import { BKKFrontpageHero } from "../ImageLibrary/imageLibrary";
import { Hero } from "../Hero/Hero";
import { AboutBKK } from "./AboutBKK";
import { BKKProgramme } from "./BKKProgramme";
import { BKKHistory } from "./BKKHistory";
import { BKKAchievements } from "./BKKAchievements";
import { PrincipalsMessage } from "./PrincipalsMessage";
import "./BKK.scss";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Scroll from "react-scroll";
import { BKKTestimonials } from "./BKKTestimonials";
import { BKKParentsPortalLink } from "./BKKParentsPortalLink";
import { startCase } from "lodash";
import { usePageUtils } from "../usePageUtils";

export const BKK = () => {
  const { hash } = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const { setTitleAndGroup } = usePageUtils();

  const scroll = useCallback(() => {
    if (hash === "") return;
    Scroll.scroller.scrollTo(hash.substring(1), {
      duration: 600,
      smooth: true,
      offset: window.innerWidth > 720 ? -80 : -65,
    });
  }, [hash]);

  useEffect(() => {
    if (firstLoad) {
      // Delay a bit, so that the page is mounted properly
      setTimeout(scroll, 100);
    } else {
      scroll();
    }

    let title = "Kindergarten";
    if (hash !== "") {
      title = startCase(hash.substring(1).replace("-", " "));
    }

    setTitleAndGroup(title, "BKK");

    setFirstLoad(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad, hash, scroll]);

  return (
    <div id="bkk">
      <Hero img={[BKKFrontpageHero]} width={4060} height={2310}/>
      <PrincipalsMessage />
      <BKKTestimonials />
      <AboutBKK />
      <BKKHistory />
      <BKKAchievements />
      <BKKProgramme />
      <BKKParentsPortalLink />
    </div>
  );
};
