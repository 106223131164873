import parse from "html-react-parser";
import { SermonInterface } from "../../components/Interfaces/Interfaces";
import { useState } from "react";

export const Services = (props: { sermon: SermonInterface }) => {
  const [service, setService] = useState(props.sermon.service_data[0]);

  return (
    <div id="sermon-post-services">
      <div className="row">
        {props.sermon.service_data.map((x) => {
          let btnClass = "btn ";
          if (x.service.name === service.service.name) {
            btnClass += "btn-primary";
          } else {
            btnClass += "btn-secondary";
          }

          return (
            <button
              key={x.service.name}
              className={btnClass}
              onClick={() => setService(x)}
            >
              {x.service.name}
            </button>
          );
        })}
      </div>
      <VideoEmbed video={service.video_embed} />
    </div>
  );
};

const VideoEmbed = (props: { video: string }) => {
  if (props.video.length === 0) {
    return <div className="video-embed-not-yet">Video not uploaded yet</div>;
  } else {
    return <div className="video-embed">{parse(props.video)}</div>;
  }
};
