export const SelectDropdown = (props: {
  id: string;
  value: any;
  update: (value: any) => void;
  options: any[];
}) => (
  <select
    id={props.id}
    value={props.value}
    onChange={(e: any) => props.update(e.target.value)}
    style={{ marginLeft: "24px", minWidth: "72px" }}
  >
    {props.options.map((val, i) => (
      <option value={val} key={i}>
        {val}
      </option>
    ))}
  </select>
);
