import { Hero } from "../Hero/Hero";
import {
  WhoIsJesusBannerHero,
  WhoIsJesusComics,
} from "../ImageLibrary/imageLibrary";
import "./WhoIsJesus.scss";
import { usePageUtils } from "../usePageUtils";

export const WhoIsJesus = () => {
  usePageUtils().setTitleAndGroup("Who is Jesus", "General");

  return (
    <div className="who-is-jesus">
      <Hero img={[WhoIsJesusBannerHero]} width={2560} height={1137} />
      <div className="content-holder">
        <h2>Who is Jesus?</h2>
        <p>
          ‘Saviour of the World, King of Kings, Lord of Lords, Creator of the
          Universe, Son of God.’
        </p>
        <p>
          Ask any person ‘Who is Jesus?’, and these are probably some answers
          you might get. We tend to think of Jesus in terms of His divinity
          –especially for those who choose to worship Him.
        </p>
        <p>He is God after-all, right?</p>
        <p>
          Well, the thing is, this ‘God’ also spent time on earth as a Man. In
          fact, there’s a whole story behind it. Here’s how I would tell it:
        </p>
        <div className="comic-strip">
          {WhoIsJesusComics.map((img, i) => {
            return (
              <img
                className="comic"
                src={img}
                alt={"Who is Jesus-" + i}
                key={"who_is_jesus_comic_" + i}
              />
            );
          })}
        </div>
        <p>
          That’s quite a story, isn’t it? It puts superhero movies to shame.
          From time to time, I wonder what it would have been like to live in
          those times and to meet Jesus&ndash;God&ndash;as a Man.
        </p>
        <p>
          I imagine myself curiously following crowds, listening quietly to His
          stories and teachings, maybe even with the hope that He would notice
          me. The Bible reveals that He loved having meals with
          people&ndash;perhaps I could have shared a meal with God Himself.
        </p>
        <p>
          What would I say? Maybe I would be too scared to talk. What kind of
          God eats dinner with the people He created? Why? It is both puzzling
          yet fascinating.
        </p>
        <p>But wait&ndash;there’s more.</p>
        <p>
          This ‘God’ spent His early life running away from mass infanticide,
          possibly living like a refugee. He was likely the subject of
          ‘town-gossip’ as the boy whose father was not his biological one, and
          lived humbly as a simple carpenter. He was once called a “friend of
          sinners”&ndash;known for hanging out with the outcasts of society. He
          was ridiculed, laughed at, and even died as a criminal although He was
          wrongly accused.
        </p>
        <p>What kind of God-Man is this?</p>
        <p>
          The truth is, the more I learn about this Jesus, the less I realise I
          know, and the more I want to find out. Maybe you feel the same way.
        </p>
        <p>
          If you do, drop us a note below or walk in to join us in discovering
          more about this Person who is at the centre of the Christian message.
        </p>
        <p>
          And perhaps someday, we’ll both be sitting with Jesus Himself,
          enjoying a meal together, hearing some of this stuff directly from
          Him.
        </p>
      </div>
    </div>
  );
};
