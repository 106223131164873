import {
  faCalculatorSimple,
  faChalkboardUser,
  faCommentPen,
  faDrum,
  faEarthAsia,
  faLanguage,
  faPaintbrushFine,
  faTrees,
} from "@fortawesome/pro-thin-svg-icons";
import { BtnsWithInfo } from "../AboutUs/AboutBFEC/BtnsWithInfo";

export const BKKNurseryK1K2 = () => (
  <BtnsWithInfo
    items={[
      {
        icon: faCalculatorSimple,
        name: "numeracy",
        content: (
          <div>
            <h5>Numeracy</h5>
            <p>
              We adopt a framework that develops conceptual understanding using
              a range of powerful visual models. Children have many experiences
              with real objects, classroom materials and pictures before symbols
              are introduced. This creates rich classroom discussions and
              language development as well.
            </p>
          </div>
        ),
      },
      {
        icon: faCommentPen,
        name: "literacy",
        content: (
          <div>
            <h5>Literacy and English Language</h5>
            <p>
              Children are introduced to Synthetic phonics – a method of
              teaching phonics where words are broken up into the smallest units
              of sound (phonemes). The 42 letter sounds taught are building
              blocks that children use to blend together to decode words and
              sentences. Readers / Books are used to reinforce the learning of
              letter sounds and blends. This lays the foundation for children’s
              formal introduction to writing and develop a love for the
              language.
            </p>
          </div>
        ),
      },
      {
        icon: faLanguage,
        name: "mandarin",
        content: (
          <div>
            <h5>Mandarin Language</h5>
            <p>
              We have extended the Stellar Approach to the teaching of Mandarin.
              Children learn the language through Shared Book and Language
              Experience Approaches, dramatization, language games, stories,
              rhymes and picture conversation. Children learn the basic strokes
              at Nursery level, progress to phrases at K1 and sentence
              construction at K2. They learn to recognize the Chinese characters
              through the use of readers and this is reinforced with our
              teacher-prepared materials.
            </p>
          </div>
        ),
      },
      {
        icon: faDrum,
        name: "music",
        content: (
          <div>
            <h5>Music and Movement</h5>
            <p>
              Singing games and songs relating to the themes for the terms are
              used to reinforce the children’s learning experiences. Children
              are immersed in musical activities that emphasize free movement.
              They learn basic rhythmic patterns using percussion instruments.
              Stories come alive as children are given opportunities to sharpen
              their creative imaginative skills through musical exploration.
            </p>
          </div>
        ),
      },
      {
        icon: faPaintbrushFine,
        name: "art-and-craft",
        content: (
          <div>
            <h5>Art and Craft</h5>
            <p>
              We provide a variety of developmentally appropriate art materials
              / tools and writing tools for children to create and write about
              the experiences they value. These art materials and tools allow
              for mastery and challenge – fewer choices for our Pre-Nursery and
              Nursery children which they can use successfully with minimal help
              and wider choices for our K1 and K2 children so as to ignite their
              artistic potential.
            </p>
            <p>
              The art experiences the children engaged in help and motivate them
              towards nurturing learning dispositions, for example, children
              learn to persevere in a group art project.
            </p>
          </div>
        ),
      },
      {
        icon: faChalkboardUser,
        name: "learning-centre",
        content: (
          <div>
            <h5>Learning Centre Activities</h5>
            <p>
              We provide various learning centres within the classroom setting,
              namely, Numeracy, Language Arts, Dramatic, Construction,
              Manipulatives, and Art for independent learning and to reinforce
              and revise concepts learnt.
            </p>
          </div>
        ),
      },
      {
        icon: faTrees,
        name: "outdoor",
        content: (
          <div>
            <h5>Outdoor Activities</h5>
            <p>
              Learning through fundamental movement skills contribute to the
              cognitive, psychomotor, physical and affective development of the
              child.
            </p>
            <p>
              In addition to water play and sand play, the two structured
              playgrounds provided children with opportunities to learn to move
              and climb. Children also have opportunities on push bikes,
              tricycles and kids skate scooter during the outdoor play time.
              Learning of skills through games and obstacle relays are also part
              of the Motor Skills curriculum for the children.
            </p>
          </div>
        ),
      },
      {
        icon: faEarthAsia,
        name: "discovery",
        content: (
          <div>
            <h5>Discovery of the World</h5>
            <p>
              Teachers model the use of open-ended questions in order to
              stimulate more language use, acknowledge that there can be many
              solutions to one problem, affirm children’s ideas, and encourage
              creative thinking.
            </p>
            <p>
              Children are naturally curious individuals and we want to see
              children asking questions and growing into creative and critical
              thinkers. We teach the 5 ‘Ws’ and 1 ‘H’ (5 wives and 1 husband)
              question starters as tools.
            </p>
          </div>
        ),
      },
    ]}
  />
);
