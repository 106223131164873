import React, { useContext } from "react";
import {
  AnnouncementInterface,
  Bulletin,
  File,
  SermonInterface,
  ServiceTagData,
  SpeakerSeriesData,
  TestimonyInterface,
} from "../components/Interfaces/Interfaces";
import { makeFixture } from "../testing/MockFirebaseUser";

export interface AdminDataContextInterface {
  announcements: AnnouncementInterface[];
  sermons: SermonInterface[];
  testimonies: TestimonyInterface[];
  series: SpeakerSeriesData[];
  speakers: SpeakerSeriesData[];
  services: ServiceTagData[];
  tags: ServiceTagData[];
  files: File[];
  bulletins: { [k: string]: Bulletin };
  fetchAllData: () => void;
}

const defaultContext: AdminDataContextInterface = {
  announcements: [],
  sermons: [],
  series: [],
  speakers: [],
  testimonies: [],
  services: [],
  tags: [],
  files: [],
  bulletins: {},
  fetchAllData: () => {},
};

export const makeAdminDataContextFixture = (
  overrides: Partial<AdminDataContextInterface> = {}
): AdminDataContextInterface => {
  return makeFixture(defaultContext, overrides);
};

export const AdminDataContext = React.createContext(defaultContext);

export const useAdminDataContext = () => useContext(AdminDataContext);
