import { Link } from "react-router-dom";
import { WhatsOnHeroes } from "../ImageLibrary/imageLibrary";
import { AnnouncementInterface } from "../../components/Interfaces/Interfaces";
import { formatURL, prettyDate } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbTack } from "@fortawesome/pro-solid-svg-icons";

export const AnnouncementCard = (props: { data: AnnouncementInterface }) => {
  const { data } = props;

  return (
    <Link
      to={`/get-plugged-in/latest/${data.slug}`}
      className={"card announcements two"}
    >
      <div className="card-content">
        <img
          className="card-img"
          src={
            data.image_link && data.image_link.String && data.image_link.Valid
              ? formatURL(data.image_link.String)
              : WhatsOnHeroes[0]
          }
          alt={
            data.image_alt.String && data.image_alt.Valid
              ? data.image_alt.String
              : ""
          }
        />
        <div className="card-text">
          <div className="card-date date">
            {data.published_on.Time && prettyDate(data.published_on.Time)}
          </div>
          <div className="card-title">
            {data.pinned && (
              <FontAwesomeIcon icon={faThumbTack} className="pinned-icon" />
            )}
            {data.title}
          </div>

          {data.synopsis.Valid && data.synopsis.String && (
            <div className="card-blurb">
              <p>{data.synopsis.String}</p>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
