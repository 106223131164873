import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { usePublicContext } from "../../../public/PublicContext";
import { getPathPrefix } from "../../../utils";

export const BackBtn = () => {
  const { prevPathname } = usePublicContext();
  const [link, setLink] = useState("/");

  useEffect(() => {
    const prevPrefix = getPathPrefix(prevPathname);
    const currPrefix = getPathPrefix(window.location.pathname);

    if (prevPrefix === currPrefix) {
      setLink(currPrefix);
    } else {
      setLink(prevPathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link className="back-btn" to={link}>
      <FontAwesomeIcon icon={faArrowLeft} />
      Back
    </Link>
  );
};
