import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PublicApp } from "./public/PublicApp";
import "./styles/App.scss";
import "./styles/animations.scss";
import { Loading } from "./components/Loading/Loading";
import { ampli } from "./ampli";
import { getEnv } from "./utils";

const AdminAppContainer = React.lazy(() => import("./admin/AdminApp"));

const App = () => {
  useEffect(() => {
    const environment = getEnv();
    console.log(environment);

    if (!ampli.isLoaded)
      ampli.load({
        environment,
        client: {
          configuration: {
            defaultTracking: {
              pageViews: {
                trackHistoryChanges: "all",
              },
            },
          },
        },
      });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<PublicApp />} />
        <Route
          path="/admin/*"
          element={
            <React.Suspense fallback={<Loading />}>
              <AdminAppContainer />
            </React.Suspense>
          }
        />
      </Routes>
    </Router>
  );
};
export default App;
