import { Hero } from "../../Hero/Hero";
import { CouncilBanner } from "../../ImageLibrary/imageLibrary";

export const Council = () => (
  <div id="people-council">
    <Hero img={CouncilBanner} width={6000} height={4000} />
    <h1>Elders &amp; Deacons</h1>
    <div id="people-council-names">
      <div className="name-list">
        <h2>Chairman</h2>
        <div style={{ marginBottom: "32px" }}>Danny Chua</div>
        <h2>Elders</h2>
        <ol>
          <li>Danny Chua</li>
          <li>Alex Lo</li>
          <li>Jimmy Tan</li>
          <li>Chua Soon Boon</li>
          <li>See Toh Ming Yew</li>
          <li>Ethan Pang</li>
          <li>Jason Sin</li>
          <li>Jonathan Cho</li>
          <li>Derek Pang</li>
          <li>Daniel Lau</li>
        </ol>
        <h2>Co-opted Elders</h2>
        <ol>
          <li>Daryl Liu</li>
          <li>Du Jian Hua</li>
          <li>Nick Chung</li>
        </ol>
        <h2>Advisory Elders</h2>
        <ol>
          <li>Lionel Ong</li>
          <li>Ernest Chew</li>
          <li>Melville Szto</li>
          <li>Roland Tan</li>
          <li>Chong Kum Wah</li>
          <li>Quek Mong Hua</li>
        </ol>
      </div>
      <div className="name-list">
        <h2>Deacons</h2>
        <ol>
          <li>
            Vincent Lim{" "}
            <span className="ministry-name">— Faith@Home, Personnel Comm</span>
          </li>
          <li>
            Kiang Tzy Peng <span className="ministry-name">— WATCH</span>
          </li>
          <li>
            Ngoo Chih Yuan <span className="ministry-name">— Estate</span>
          </li>
          <li>
            John Teo <span className="ministry-name">— IT / Tech</span>
          </li>
          <li>
            Ng Geok Chye <span className="ministry-name">— Evangelism</span>
          </li>
          <li>
            Michael Mak <span className="ministry-name">— Finance Comm</span>
          </li>
          <li>
            Willy Ong <span className="ministry-name">— Missions</span>
          </li>
          <li>
            Kelvin Ng <span className="ministry-name">— Social Action</span>
          </li>
          <li>
            Joseph Oh <span className="ministry-name">— Finance Comm</span>
          </li>
          <li>
            James Chong <span className="ministry-name">— Treasurer, Finance Comm</span>
          </li>
          <li>
            Lee Yen Ter <span className="ministry-name">— Youth Service</span>
          </li>
          <li>
            Fong Chee Siong <span className="ministry-name">— Chinese Ministry</span>
          </li>
          <li>
            Marcus Wong <span className="ministry-name">— Camp Commandant, KS Comm</span>
          </li>
        </ol>
        <h2>Deaconesses</h2>
        <ol>
          <li>
            Shirley Soo <span className="ministry-name">— Operations</span>
          </li>
          <li>
            Wong Lee Pin <span className="ministry-name">— BKK</span>
          </li>
          <li>
            Sharon Ngoo{" "}
            <span className="ministry-name">— Worship / Youth Service</span>
          </li>
          <li>
            Abigail Ng <span className="ministry-name">— Youth Service</span>
          </li>
          <li>
            Chan Hsin Yee <span className="ministry-name">— Young Adults</span>
          </li>
        </ol>
      </div>
    </div>
  </div>
);
