import parse from "html-react-parser";

export const Verse = (props: {
  children: string;
  verseRef: string | JSX.Element;
}) => (
  <div className="verse">
    {parse(props.children)}
    <div className="verse-ref">{props.verseRef}</div>
  </div>
);
