import { useEffect } from "react";
import { usePublicContext } from "../PublicContext";
import { prettyDate } from "../../utils";
import { SermonsHero } from "../ImageLibrary/imageLibrary";
import { Link, useLocation } from "react-router-dom";
import Scroll from "react-scroll";
import { GivingContent } from "../GivingContent";

const LatestSermon = () => {
  const { latestSermon } = usePublicContext();
  if (!latestSermon || !latestSermon.sermon_date.Time) return <></>;

  const img = latestSermon.series.image_link.String || "";

  return (
    <section id="latest-sermon">
      <img
        src={img !== "" ? img : SermonsHero}
        alt="Sermon graphic"
        id="latest-sermon-image"
      />
      <section id="sermon-details">
        <div id="sermon-date">{prettyDate(latestSermon.sermon_date.Time)}</div>
        <div id="sermon-title">{latestSermon.title}</div>
        <div id="sermon-speaker">
          {latestSermon.speaker.map((s) => s.name).join(", ")}
        </div>
        <div className="sermon-buttons">
          <Link
            to={"/get-plugged-in/sermons/" + latestSermon.slug}
            className="btn btn-primary"
          >
            Sermon Notes
          </Link>
        </div>
      </section>
    </section>
  );
};
export const Tithing = () => {
  const { announcements } = usePublicContext();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === "#give" && announcements != null) {
      setTimeout(() => {
        Scroll.scroller.scrollTo("give", {
          smooth: true,
          duration: 300,
          offset: window.innerWidth > 720 ? -120 : -90,
          isDynamic: true,
        });
      }, 200);
    }
  }, [hash, announcements]);

  return (
    <section id="tithing">
      <Scroll.Element name="give" />
      <h2>Tithing / Offering</h2>
      <GivingContent />
    </section>
  );
};
export const WhatsOnSideBar = (props: { desktop: boolean }) => (
  <div id="whats-on-left-bar">
    <LatestSermon />
    {props.desktop && <Tithing />}
  </div>
);
