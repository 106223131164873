import { Hero } from "../Hero/Hero";
import { BKKGraduationGown } from "../ImageLibrary/imageLibrary";

export const BKKHistory = () => (
  <div className="red">
    <Hero img={[BKKGraduationGown]} width={1600} height={900} />

    <div className="content-holder">
      <h2>Our History</h2>
      <p>
        Bethesda (Katong) Kindergarten started at Jalan Pipit (now Dunbar Walk)
        in 1953. We had 20 students then, aged from 4 to 6, who registered for
        our nursery and pre-primary programmes. As the kindergarten grew
        significantly both in quality of education and enrolment, new premises
        were required and in 1955, the foundation stone for a one-storey
        kindergarten building in La Salle Street was laid. In 1996, Bethesda
        (Frankel Estate) church took over the administration and running of the
        kindergarten from Bethesda (Katong) Church.
      </p>
      <p>
        Today we offer classes from Playgroup to K2, with a total of more than
        200 children.
      </p>
      <p>All Glory and Praise to God for His countless blessings to us.</p>
    </div>
  </div>
);
