import { Link } from "react-router-dom";
import { HeroText } from "../../Hero/Hero";
import { AboutBFECHeroes } from "../../ImageLibrary/imageLibrary";
import "./AboutBFEC.scss";
import { OurStory } from "./OurStory";
import { WhatWeBelieve } from "./WhatWeBelieve";
import { WhatWePractise } from "./WhatWePractise";
import { usePageUtils } from "../../usePageUtils";

export const AboutBFEC = () => {
  usePageUtils().setTitleAndGroup("About BFEC", "Our Community");

  return (
    <>
      <HeroText
        text="About BFEC"
        img={AboutBFECHeroes}
        align="top-right"
        width={4000}
        height={1778}
      />
      <div className="content-holder">
        <WhereEveryFace />
        <MissionVisionValues />
        <Denomination />
        <OurStory />
        <WhatWeBelieve />
        <WhatWePractise />
        <div className="bottom-text">
          For further reading, please refer to “Bethesda (Frankel Estate) Church
          Faith & Practices” Chapter 4 & 5 on “Doctrine of the Triune God &
          Doctrine of the Church”.
        </div>
      </div>
    </>
  );
};

const WhereEveryFace = () => (
  <div>
    <h2>Where Every Face is Familiar</h2>
    <p>
      This page is all about us&mdash;our story, our beliefs, and the practices
      we uphold as a church.
    </p>
    <p>
      Nestled within the East Coast, Bethesda Frankel Estate Church has come to
      be known for its warm, family feel, where newcomers are always welcomed
      with smiles and open arms. And if you want to know what that’s like, there
      are many ways to do so! Our{" "}
      <Link to="/our-community/ministries">ministries</Link> and cell groups
      offer spaces for people of different age groups and interests to gather in
      small groups, to build meaningful relationships with fellow brothers and
      sisters in Christ. Or join us on a Sunday, when we gather as a larger
      family to worship. Our <Link to="/contact-us">worship services</Link>{" "}
      include both traditional and contemporary Christian songs, and we practise
      the Lord’s Supper weekly.
    </p>
    <p>We hope you will feel at home here with us!</p>
  </div>
);

const MissionVisionValues = () => (
  <div>
    <h2>Mission, Vision, Values</h2>
    <h3>Our Vision</h3>
    <p>“To be the bride of Christ ready at His return.”</p>
    <h3>Our Mission</h3>
    “We spur one another to grow as disciples of Christ living out fully God’s
    purposes for our lives.”
    <h3>Our Core Faith Values</h3>
    <table id="core-faith-values">
      <tbody>
        <tr>
          <th>R</th>
          <td>Rooted in the Living Word</td>
        </tr>
        <tr>
          <th>E</th>
          <td>Empowered by the Holy Spirit</td>
        </tr>
        <tr>
          <th>A</th>
          <td>Abiding in Christ only</td>
        </tr>
        <tr>
          <th>D</th>
          <td>Daring to Hope and Love</td>
        </tr>
        <tr>
          <th>Y</th>
          <td>Yielding Fruit To God’s Glory</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const Denomination = () => (
  <div>
    <h2>What denomination do we belong to?</h2>
    <p>
      Bethesda Frankel Estate Church has its roots in the modern Christian
      Brethren movement. It began in early 19th century Britain, when Christians
      from various churches met in non-denominational groups for Bible study,
      prayer, worship, and witness. Philip Robinson, an English merchant who
      founded the commercial Robinsons, formed the first Brethren congregation
      in Singapore in 1864. Other Brethren churches include Bethesda Katong
      Church, Bethesda Bedok-Tampines Church, and Bethesda Pasir Ris Mission
      Church.
    </p>
    <p>
      A key tenet of the Brethren movement is that Christ is the recognised head
      of the church, not any person, group or religious organisation. Thus, we
      believe that each church should be self-governing and autonomous,
      determining their own strategy, structure, and style. This is why our
      sister churches, while we may share the name ‘Bethesda’, each have their
      distinct identity. Each church comes under the direction of their own
      council of elders and deacons, and you can find out more about ours{" "}
      <Link to="/our-community/people">here</Link>.
    </p>
  </div>
);
