import Obfuscate from "react-obfuscate";

export const EmailLink = (props: {
  to: string;
  subject?: string;
  domain?: string;
}) => {
  const domain = props.domain || "bfec.org.sg";
  let em: string;

  if (props.to.search("@") !== -1) {
    em = props.to;
  } else {
    em = props.to + "@" + domain;
  }

  if (props.subject) {
    return <Obfuscate email={em} headers={{ subject: props.subject }} />;
  } else {
    return <Obfuscate email={em} />;
  }
};
