import {
  faBible,
  faChurch,
  faComments,
  faDroplet,
  faHandsHoldingDollar,
  faPeopleGroup,
  faWind,
} from "@fortawesome/pro-thin-svg-icons";
import { Verse } from "../../../components/Verse/Verse";
import { BtnsWithInfo } from "./BtnsWithInfo";

export const WhatWePractise = () => (
  <BtnsWithInfo
    header="What do we practise?"
    items={[
      {
        icon: faBible,
        name: "Bible As Our Sole Authority",
        content: (
          <>
            <Verse verseRef="2 Timothy 3:16–17 (NIV)">
              “All Scripture is God-breathed and is useful for teaching,
              rebuking, correcting and training in righteousness, so that the
              man of God may be thoroughly equipped for every good work.”
            </Verse>
            <p>
              Since God’s Word is the only completely reliable and truthful
              authority, we accept the Bible as our manual for living. Our first
              question when faced with a decision is “What does the Bible say?”
              We practise daily Bible reading, Bible study and Bible
              memorization. The Bible is the basis for all we believe.
            </p>
          </>
        ),
      },
      {
        icon: faChurch,
        name: "Autonomy Of Each Local Church",
        content: (
          <>
            <Verse verseRef="Colossians 1:18 (NIV)">
              “And He is the head of the body, the church; He is the beginning
              and the firstborn from among the dead, so that in everything He
              might have the supremacy.”
            </Verse>
            <p>
              Christ is the recognised head of our church, not any person, group
              or religious organisation. While recognising the value of
              associating and cooperating with other groups of Christians, we
              believe every local church should be self-governing and
              independent from any denominational control. In relating to our
              “daughter churches” that we sponsor, we encourage each
              congregation to determine her own strategy, structure and style.
            </p>
          </>
        ),
      },
      {
        icon: faPeopleGroup,
        name: "Priesthood Of Every Believer",
        content: (
          <>
            <Verse verseRef="Revelation 1:6 (NIV)">
              “To Him who loves us and has freed us from our sins by His blood,
              and has made us to be a kingdom and priests to serve His God and
              Father—to Him be glory and power for ever and ever! Amen.”
            </Verse>
            <Verse verseRef="1 Peter 2:9 (NIV)">
              “But you are a chosen people, a royal priesthood, a holy nation, a
              people belonging to God, that you may declare the praises of Him
              who called you out of darkness into His wonderful light.”
            </Verse>
            <p>
              The Bible teaches that every Christian is called to “full-time”
              Christian service, regardless of his or her vocation. We practise
              the truth that every believer is a minister by encouraging every
              member to find a place of service and ministry. Every believer has
              direct access to God through prayer and Bible reading.
            </p>
          </>
        ),
      },
      {
        icon: faHandsHoldingDollar,
        name: "Tithing",
        content: (
          <>
            <Verse verseRef="Leviticus 27:30 (NIV)">
              “A tithe of everything from the land, whether grain from the soil
              or fruit from the trees, belongs to the LORD; it is holy to the
              LORD.”
            </Verse>
            <p>
              We practise tithing for the support of Christ’s body, the church,
              as God commands. We recognise that giving at least 10% of our
              income is both biblical and honoring to God.
            </p>
            <Verse verseRef="2 Corinthians 9:6–8 (NIV)">
              “Remember this: Whoever sows sparingly will also reap sparingly,
              and whoever sows generously will also reap generously. Each man
              should give what he has decided in his heart to give, not
              reluctantly or under compulsion for God loves a cheerful giver.
              And God is able to make all grace abound to you, so that in all
              things at all times, having all that you need, you will abound in
              every good work.”
            </Verse>
          </>
        ),
      },
      {
        icon: faDroplet,
        name: "Immersion",
        content: (
          <>
            <Verse verseRef="Colossians 2:11–12 (NIV)">
              “In Him you were also circumcised, in the putting off of the
              sinful nature, not with a circumcision done by the hands of men
              but with the circumcision done by Christ, having been buried with
              Him in baptism and raised with Him through your faith in the power
              of God, who raised Him from the dead.”
            </Verse>
            <p>
              From the New Testament, it would seem that immersion was the
              common practice. This mode is currently practised in our church
              (Matthew 3:16). Sprinkling is another form. The very elderly and
              believers who are sick, are baptized by sprinkling or pouring a
              little water on the head.
            </p>
          </>
        ),
      },
      {
        icon: faWind,
        name: "Spirit-led Living",
        content: (
          <>
            <Verse verseRef="John 15:5 (NIV)">
              “I am the vine; you are the branches. If a man remains in me and I
              in him, he will bear much fruit; apart from me you can do
              nothing.”
            </Verse>
            <p>
              We believe the only way possible to live the Christian life is by
              God’s power within us. So we seek to practice a daily dependence
              on God’s Spirit to enable us to do what is right. In doing this,
              we become truly charismatic (move in the gifts of the Spirit).
            </p>
            <p>Philippians 2:13; Ephesians 5:18</p>
          </>
        ),
      },
      {
        icon: faComments,
        name: "Telling Others About Christ",
        content: (
          <>
            <Verse verseRef="1 Peter 3:15–16 (NIV)">
              “But in your hearts set apart Christ as Lord. Always be prepared
              to give an answer to everyone who asks you to give the reason for
              the hope that you have. But do this with gentleness and respect,
              keeping a clear conscience, so that those who speak maliciously
              against your good behavior in Christ may be ashamed of their
              slander.”
            </Verse>
            <p>
              It is the responsibility of every Christian to share the Good News
              with those with whom God brings us into contact. We practise
              personal sharing about Christ and inviting friends to church.
            </p>
          </>
        ),
      },
    ]}
  />
);
