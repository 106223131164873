import { useEffect, useState } from "react";
import { MinistriesData } from "./MinistriesData";
import { MinistryBody } from "./MinistryBody";
import { OptionsContext } from "./MinistryOptionsContext";
import {
  PeopleMinistrySelector,
  MobileSelector,
} from "./PeopleMinistrySelector";
import Scroll from "react-scroll";

export const PeopleMinistries = () => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedOption, setOption] = useState("");

  const scroll = () => {
    if (window.scrollY < 800) {
      Scroll.scroller.scrollTo("ministry", {
        duration: 400,
        isDynamic: true,
        smooth: true,
        offset: -40,
      });
    }
  };

  useEffect(() => {
    let newHash = window.location.hash;
    if (newHash === "") {
      newHash = "kids";
    } else {
      newHash = newHash.substring(1);
    }
    setOption(newHash);

    if (window.location.hash !== "") {
      if (firstLoad) {
        setTimeout(() => {
          scroll();
          setFirstLoad(false);
        }, 100);
      } else {
        scroll();
      }
    }
  }, [firstLoad]);

  const selected =
    MinistriesData.find((e) => e.link === selectedOption) || MinistriesData[0];

  const randImage =
    selected.image[Math.floor(Math.random() * selected.image.length)];

  return (
    <OptionsContext.Provider value={{ selectedOption, setOption }}>
      <div id="people-ministries">
        <h1>People Ministries</h1>
        <Scroll.Element name="ministry" />
        <PeopleMinistrySelector />
        <MobileSelector />
        <section
          className="people-ministries-body"
          style={{
            backgroundColor: selected.colour,
          }}
        >
          <div className="ministry-image">
            <img src={randImage} alt={selectedOption + " thumbnail"} />
          </div>
          <MinistryBody data={selected} />
        </section>
      </div>
    </OptionsContext.Provider>
  );
};
